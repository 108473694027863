import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHS from '~/app/utils/paths';
import { getRegionPath } from '~/app/utils/pathUtils';
import loadable from '@loadable/component';
import MyPreordersRouter from './MyPreordersRouter';
import MyOrdersRouter from './MyOrdersRouter';
import SideBarLayout from '../layouts/SideBarLayout';
import { ENABLE_NEW_ECOSYSTEM_PRODUCT, ENABLE_USER_RESOURCES } from '../settings';

const MyVehicleCollection = loadable(() => import(/* webpackChunkName: "MyVehicleCollection" */ '~/app/pages/MyVehicleCollection'));
const MyEvents = loadable(() => import(/* webpackChunkName: "MyEvents" */ '~/app/pages/MyEvents'));
const FFPoints = loadable(() => import(/* webpackChunkName: "FFPoints" */ '~/app/pages/RewardPoints'));
const ReferrerProgram = loadable(() => import(/* webpackChunkName: "ReferrerProgram" */ '~/app/pages/ReferralPage/ReferralProgram'));
const MyDesignPage = loadable(() => import(/* webpackChunkName: "MyDesignPage" */ '~/app/pages/MyDesignPage'));
const DesignDetailsPage = loadable(() => import(/* webpackChunkName: "DesignDetailsPage" */ '~/app/pages/DesignDetailsPageV2'));
const NewMyAccountPage = loadable(() => import(/* webpackChunkName: "NewAccountPage" */ '~/app/pages/NewAccountPage'));
const DeliveryLocationPage = loadable(() => import(/* webpackChunkName: "DeliveryLocationPage" */ '~/app/pages/DeliveryLocationPage'));
const BillingPage = loadable(() => import(/* webpackChunkName: "BillingPage" */ '~/app/pages/BillingPage'));
const EcoProductDetailsPage = loadable(() => import(/* webpackChunkName: "EcoProductDetailsPage" */ '~/app/pages/EcoProductPage/EcoProductDetailsPage'));
const EcoProductPage = loadable(() => import(/* webpackChunkName: "EcoProductPage" */ '~/app/pages/EcoProductPage'));
const UserResources = loadable(() => import(/* webpackChunkName: "UserResourcesPage" */ '~/app/pages/UserResourcesPage'));

const NewMyFFRouter = () => (
  <SideBarLayout>
    <Switch>
      <Route exact path={getRegionPath(PATHS.MY_FF)} component={MyVehicleCollection} />
      <Route exact path={getRegionPath(PATHS.MY_ACCOUNT)} component={NewMyAccountPage} />
      <Route path={getRegionPath(PATHS.MY_RESERVATIONS)} component={MyPreordersRouter} />
      <Route path={getRegionPath(PATHS.ORDERS)} component={MyOrdersRouter} />
      <Route exact path={getRegionPath(PATHS.MY_EVENTS)} component={MyEvents} />
      <Route exact path={getRegionPath(PATHS.MY_REFERRALS)} component={ReferrerProgram} />
      <Route exact path={getRegionPath(PATHS.MY_FF_POINTS)} component={FFPoints} />
      <Route path={getRegionPath(PATHS.DESIGN_DETAILS)} component={DesignDetailsPage} />
      <Route path={getRegionPath(PATHS.MY_DESIGNS)} component={MyDesignPage} />
      <Route path={getRegionPath(PATHS.DELIVERY_LOCATION)} component={DeliveryLocationPage} />
      <Route path={getRegionPath(PATHS.BILLING)} component={BillingPage} />
      {ENABLE_USER_RESOURCES && <Route path={getRegionPath(PATHS.MY_USER_RESOURCES)} component={UserResources} />}
      {ENABLE_NEW_ECOSYSTEM_PRODUCT && <Route path={getRegionPath(PATHS.ECOSYSTEM_PRODUCT_DETAILS)} component={EcoProductDetailsPage} />}
      {ENABLE_NEW_ECOSYSTEM_PRODUCT && <Route path={getRegionPath(PATHS.MY_ECOSYSTEM_PRODUCT)} component={EcoProductPage} />}
      <Redirect to="/:locale(us|cn)/404" />
    </Switch>
  </SideBarLayout>
);

export default NewMyFFRouter;
