import React, { useState } from 'react';
import PATHS from '~/app/utils/paths';
import { getSameRegionPath as rp } from '~/app/utils/pathUtils';
import { trackUIEvent } from '~/app/utils/analyticUtils';
import ErrorBound from '@ff/ui/ErrorBoundary';
import UIError from '../utils/UIError';

export default function ErrorBoundary(props) {
  const [data, setData] = useState({ message: '', link: rp(PATHS.MY_FF) });

  const handleError = (error, errorInfo) => {
    if (error instanceof UIError && error.data) {
      setData(error.data);
    }

    trackUIEvent('Error Start Over', window.location.href);
    console.error(error, errorInfo);
  };

  return (
    <ErrorBound
      minHeight='100vh'
      onError={handleError}
      message={data.message}
      link={data.link}
    >
      {props.children}
    </ErrorBound>
  )
}
