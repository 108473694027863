import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import useUser from '~/app/hooks/queries/useUser';
import PATHS from '~/app/utils/paths';
import { getSameRegionPath as rp } from '~/app/utils/pathUtils';
import Loading from '~/app/components/Loading';

export default function PrivateRoute(props) {
  const { loading, isAuthenticated } = useUser();
  const location = useLocation();

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loading />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: rp(PATHS.LOGIN),
          state: { referrer: location }
        }}
      />
    );
  }

  return (
    <Route {...props} />
  );
}
