/* =============================================================================
=    Query User Profile
============================================================================= */
import { gql } from '@apollo/client';

export default gql`
query user {
  user {
    id
    firstName
    lastName
    emailSubscription
    mobile
    addressCode
    email
    fpoStatus
  }
}
`;
