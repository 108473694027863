import React from 'react';
import AuthenticatedMenuBar from '~/app/containers/Genesis/Menu/AuthenticatedMenuBar';
import Footer from '~/app/containers/Genesis/Footer/Footer';
import menuProps from 'lib/menuProps';
import footerProps from 'lib/footerProps';
import { useParams, useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import ConfigContextProvider, { useConfig } from '~/app/contexts/ConfigContextProvider';
import { styled } from '@ff/ui';
import Box from '@ff/ui/Box';
import PATHS from '~/app/utils/paths';

const Layout = styled(Box)();
Layout.defaultProps = { as: 'section' };

const Content = styled(Box)({
  minHeight: '500px',
});
Content.defaultProps = { as: 'main' };

export default function HeaderFooterLayoutContainer(props) {
  return (
    <ConfigContextProvider>
      <HeaderFooterLayout {...props} />
    </ConfigContextProvider>
  );
}

const MENU_PROPS = window?.MENU_PROPS || menuProps;
const FOOTER_PROPS = window?.FOOTER_PROPS || footerProps;

function HeaderFooterLayout({ children }) {

  const c = useStyles();
  const params = useParams();
  const { locale } = params;
  const location = useLocation();

  const { showMenuBar, showFooter } = useConfig();

  // /us/reserve, /MyFF/reservations, /us/preorder, /MyFF/preorders
  const isReserveUS = (params[0].includes('preorder') || params[0].includes('reserv')) && locale === 'us';
  const isEventUS = params[0].includes('event') && locale === 'us';
  const isPaymentUS = params[0].includes('payment') && locale === 'us';
  const isReferralUS = params[0].includes('referral') && locale === 'us';
  const isDesignUS = params[0].includes('design') && locale === 'us';
  const isLoginUS = params[0].includes('login') && locale === 'us';
  // Starts with account
  const isAccountUS = params[0].indexOf('account') === 0 && locale === 'us';
  
  // Check if the current page is the FX preorder page
  const isFXPreorderPage = 
    location.pathname === `/${locale}${PATHS.RESERVE}${PATHS.FX_SIMPLE_PREORDER}` ||
    location.pathname === `/${locale}${PATHS.APP_FX_SIMPLE_PREORDER}`;
  
  // Customize MenuBar props for FX preorder page
  const customMenuProps = {
    ...MENU_PROPS,
    // Set custom logoType for FX preorder page only
    ...(isFXPreorderPage && { 
      logoType: 'FX SIMPLE V2',
      logoLink: null // Disable logo link
    })
  };

  const contentClassName = isReserveUS || isEventUS || isReferralUS || isDesignUS || isPaymentUS ? c.reserveBackground : '';
  const newContentClassName = isAccountUS ? c.shadedBackground : '';
  const contentClsResolver = isReserveUS || isLoginUS ? c.clsResolver : '';
  const newLoginClsResolver = isLoginUS ? c.flex : '';

  // Show US footer region selector only if the page isn't available for other regions.
  const availableFooterRegions = isEventUS || isReferralUS ? ['US'] : undefined;

  return (
    <Layout className={cx('ant-layout', c.newLayoutDefault, newContentClassName)}>
      <div id="banner-portal" />
      {showMenuBar && <AuthenticatedMenuBar {...customMenuProps} />}
      <Content
        className={cx('ant-layout-content', contentClsResolver, c.flex, c.contentPadding)}
      >
        {children}
      </Content>
      {showFooter && <Footer availableRegions={availableFooterRegions} {...FOOTER_PROPS} />}
    </Layout>
  );
}

const useStyles = createUseStyles({
  newLayoutDefault: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  reserveBackground: {
    backgroundColor: '#ffffff',
  },
  shadedBackground: {
    backgroundColor: 'white',
  },
  clsResolver: {
    minHeight: 'calc(100vh - 88px)'
  },
  flex: {
    '@media(min-width: 600px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '1920px',
      width: '100%',
      paddingTop: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
  },
  contentPadding: {
    // Handled by MenuBar spacer
    paddingTop: '0px',
    paddingBottom: 0
  }
});
