export const CURRENCY_TO_SYMBOL = {
  USD: '$',
  RMB: '￥'
};

export const ENV_DOMAINS = {
  LOC: 'localhost',
  DEV_CN: 'https://dev-web-cn.ffau.to/cn/',
  DEV_US: 'https://dev-web.ffau.to/us/',
  QA_CN: 'https://qa-web-cn.ffau.to/cn/',
  QA_US: 'https://qa-web.ffau.to/us/',
  STG_CN: 'https://staging-web-cn.ffau.to/cn/',
  STG_US: 'https://staging-web.ffau.to/us/',
  PRD_CN: 'https://www.faradayfuturecn.com/cn/',
  PRD_US: 'https://www.ff.com/us/'
};

export const APP_RESERVE_STATE = Object.freeze({
  APP_USER_LOGIN: 0,
  SELECT_RESERVE_TYPE: 1,
  FILL_USER_INFO: 2,
  FILL_BILL_INFO: 3,
  RESERVE_AND_PAY: 4
});

export const APP_RESERVE_TYPE = Object.freeze({
  FF91_FUTURIST_ALLIANCE: '91FA',
  FF91_FUTURIST: '91FS',
  FF91: 'FF91'
});

export const CONTACT_EMAIL = 'sales@ff.com';
export const CONTACT_PHONE = '18002287702';
export const CONTACT_PHONE_FORMAT = '1 (800) 228-7702';
export const FF91_TEXT = 'FF 91 2.0';
