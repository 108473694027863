import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import Header2 from '@ff/ui/Header2';
import { mapLoggedInLabel } from '@ff/ui/Header2/utils';
import zIndices from '~/app/utils/zIndices';

class MenuBar extends React.Component {
  render() {
    const {
      classes: c,
      drawerLinks,
      menubarLinks,
      isLoggedIn,
      ctaButton,
      logoType,
      colorTheme='LIGHT',
      float=false,
      spacer=true,
      ...rest
    } = this.props;

    return (
      <Header2
        drawerLinks={mapLoggedInLabel(drawerLinks, isLoggedIn)}
        menubarLinks={mapLoggedInLabel(menubarLinks, isLoggedIn)}
        ctaButton={ctaButton}
        isLoggedIn={isLoggedIn}
        colorTheme={colorTheme}
        scrollEffect={false}
        zIndex={zIndices['menubar']}
        spacer={spacer}
        logoType={logoType}
        float={float}
        {...rest}
      />
    );
  }
}

MenuBar.propTypes = {
  logo_link: PropTypes.string,
  first_set: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    loggedInLabel: PropTypes.string,
  })),
  second_set: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    loggedInLabel: PropTypes.string,
  })),
  hero_button: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    loggedInLabel: PropTypes.string,
  }),
  color_theme: PropTypes.string,
  scroll_effect: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  hasAppointments: PropTypes.bool,
  hasOrders: PropTypes.bool,
  isFetchingUser: PropTypes.bool
}

const styles = {
  container: {
    zIndex: zIndices['menubar'],
    position: 'relative',
    '& a:hover': {
      color: 'inherit',
    }
  }
};


export default injectSheet(styles)(MenuBar);
