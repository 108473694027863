import React from 'react';
import Icon from '@ff/ui/Icon';
import { styled } from '@ff/ui';
import { Paragraph2 } from '~/app/utils/typography';

export default function Item({ icon, iconStyles, text, active }) {
  return (
    <Container active={active}>
      <StyledIcon
        name={icon}
        style={iconStyles}
      />
      <ItemText active={active}>
        {text}
      </ItemText>
    </Container>
  );
}

const Container = styled.Box(({ theme, active }) => ({
  borderBottom: active ? '1px solid black' : 'none',
  display: 'flex',
  flexGrow: 1,
  height: '35px',
  alignItems: 'center',
  cursor: 'pointer',
  width: active ? '100%' : '1%',
  paddingRight: '7px',
  transition: 'all 0.3s ease-out',
  [theme.mediaQueries.md]: {
    borderBottom: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    transition: 'none',
    width: '100%',
  },
}));
Container.displayName = 'Container';

const ItemText = styled(Paragraph2)(({ theme, active }) => ({
  animation: active && `width 0.3s forwards`,
  display: active ? 'block' : 'none',
  width: active ? '100%' : '30px',
  paddingLeft: '10px',
  opacity: active ? 1 : 0,
  visibility: active ? 'visible' : 'hidden',
  transition: 'all 0.5s ease-out',
  whiteSpace: 'nowrap',
  [theme.mediaQueries.md]: {
    animation: 'none',
    display: 'block',
    marginLeft: '5px',
    paddingLeft: 0,
    opacity: 1,
    transition: 'none',
    visibility: 'visible',
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: '20px',
  paddingLeft: 0,
  paddingRight: 0,
  [theme.mediaQueries.md]: {
    minWidth: '40px',
    textAlign: 'center',
    // paddingLeft: '15px',
    // paddingRight: '15px',
  },
}));

