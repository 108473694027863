import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '~/app/containers/PrivateRoute';
import PATHS from '~/app/utils/paths';
import { getRegionPath } from '~/app/utils/pathUtils';
import loadable from '@loadable/component';

const NewOrderDetailsPage = loadable(() => import(/* webpackChunkName: "OrderDetailsPage" */ '~/app/pages/OrderDetailsPageV2'));

export default function MyPreordersRouter() {
  return (
    <Switch>
      <PrivateRoute exact path={getRegionPath(PATHS.RESERVE_DETAILS)} component={NewOrderDetailsPage} />
      <Redirect to="/:locale(us|cn)/404" />
    </Switch>
  );
}
