import { ZIndexBuilder } from '@ff/ui/utils/ZIndexBuilder';

const zIndices = new ZIndexBuilder({
  modal: {
    tooltip: {
      menubarDrawer: {
        menubar: {
          dropdown: {},
          // update z index in phoenix-defaults.less manually after changing the structure
          antdDropDown: {},
          underMenuBar: {},
          sidebar: {},
        },
      }
    }
  }
}).zIndices;

export default zIndices;
