import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Components
import HeaderFooterLayout from '~/app/layouts/HeaderFooterLayout';

// Utils
import PATHS from '~/app/utils/paths';

// Routes
import ReserveRouter from '~/app/routers/ReserveRouter';
import NewAccountRouter from '~/app/routers/NewAccountRouter';
import NewMyFFRouter from '~/app/routers/NewMyFFRouter';
import EventRouter from '~/app/routers/EventRouter';
import ReferralRouter from '~/app/routers/ReferralRouter';
import PrivateRoute from '~/app/containers/PrivateRoute';
import ReferralLandingLayout from '~/app/layouts/ReferralLandingLayout';
import EcoProductRouter from '~/app/routers/EcoProductRouter';
import { ENABLE_REFERRAL_PROGRAM, ENABLE_NEW_ECOSYSTEM_PRODUCT } from '~/app/settings';
import loadable from '@loadable/component';

const ReferralLandingPage = loadable(() => import(/* webpackChunkName: "ReferralLandingPage" */ '~/app/pages/ReferralPage'));

const ScaffoldContainer = () => {

  return (
    <Switch>
      {/* The new version referral landing page has its own header layout */}
      {ENABLE_REFERRAL_PROGRAM && (
        <Route path={`/:locale(us|cn)${PATHS.REFERRAL}`} >
          {
            ({ match }) => (
              <ReferralLandingLayout>
                <ReferralLandingPage referralCode={match?.params?.referralCode} />
              </ReferralLandingLayout>
            )
          }
        </Route>
      )}
      <HeaderFooterLayout>
        <Switch>
          <PrivateRoute path={`/:locale(us|cn)${PATHS.MY_FF}`} component={NewMyFFRouter} />
          <Route path={`/:locale(us|cn)${PATHS.ACCOUNT}`} component={NewAccountRouter} />
          <Route path={`/:locale(us|cn)${PATHS.RESERVE}`} component={ReserveRouter} />
          <Route path={`/:locale(us|cn)${PATHS.BASE_EVENT}`} component={EventRouter} />
          <Route path={`/:locale(us|cn)${PATHS.REFERRAL}`} component={ReferralRouter} />
          {ENABLE_NEW_ECOSYSTEM_PRODUCT && (
            <Route path={`/:locale(us|cn)${PATHS.ECOSYSTEM_PRODUCT_PREORDER}`} component={EcoProductRouter} />
          )}
          <Redirect to="/:locale(us|cn)/404" />
        </Switch>
      </HeaderFooterLayout>
    </Switch>
  );
};

export default ScaffoldContainer;
