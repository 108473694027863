import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import PATHS from '~/app/utils/paths';
import { getRegionPath } from '~/app/utils/pathUtils';

const EcoProductPreorderPage = loadable(() => import(/* webpackChunkName: "EcoProductPreorderPage" */ '~/app/pages/EcoProductPreorderPage'));

export default function EcoProductRouter() {
  return (
    <Switch>
      <Route exact path={getRegionPath(PATHS.ECOSYSTEM_PRODUCT_PREORDER)} component={EcoProductPreorderPage} />
      <Redirect to="/:locale(us|cn)/404" />
    </Switch>
  );
}
