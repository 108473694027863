// Whitelist of event urlPaths
const events = [
  'test-ride'
].join('|');

const MYFF = '/MyFF'

const PATHS = {
  US_BASE: '/us',
  CN_BASE: '/cn',
  MY_FF: MYFF,
  // Account info page
  ACCOUNT: '/account',
  MY_ACCOUNT: `${MYFF}/account`,
  MY_RESERVATIONS: `${MYFF}/preorders`,
  MY_EVENTS: `${MYFF}/events`,
  MY_REFERRALS: `${MYFF}/referrals`,
  MY_FF_POINTS: `${MYFF}/points`,
  MY_DESIGNS: `${MYFF}/designs`,
  VEHICLE_REGISTRATION: `${MYFF}/vehicle-registration`,
  DELIVERY_LOCATION: `${MYFF}/delivery-location`,
  BILLING: `${MYFF}/billing`,
  // Login / sign up page
  LOGIN: '/account/login',
  LOGOUT: '/account/logout',
  // Welcome, successful login
  SUCCESS: '/account/success',
  // Forgot password page
  FORGOT_PASS: '/account/forgot-password',
  // Reset password page
  RESET_PASS: '/account/reset-password/:key',

  // Order
  ORDERS: `${MYFF}/orders`,

  // Reserve
  RESERVE: '/preorder',
  // Reservation detailed view
  RESERVE_DETAILS: `${MYFF}/preorders/details/:confNum`,
  RESERVE_DETAILS_BASE: `${MYFF}/preorders/details`,
  ORDER_DETAILS: `${MYFF}/orders/details/:confNum`,
  ORDER_DETAILS_BASE: `${MYFF}/orders/details`,
  DESIGN_DETAILS: `${MYFF}/designs/details/:confNum`,
  DESIGN_DETAILS_BASE: `${MYFF}/designs/details/`,
  // My Referrals
  REFERRAL: '/referral/:referralCode',
  DESIGN: '/design',
  CONFIGURATOR: '/configurator',
  NEW_CONFIGURATOR: '/design',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment/success',
  ERROR: '/error',

  // EcoSystem Product
  ECOSYSTEM_PRODUCT_PAGE: `/ecosystem-product`,
  MY_ECOSYSTEM_PRODUCT: `${MYFF}/ecosystem-product`,
  ECOSYSTEM_PRODUCT_DETAILS: `${MYFF}/ecosystem-product/details/:itemId`,
  ECOSYSTEM_PRODUCT_PREORDER: '/ecosystem-product-preorder',
  APP_ECOSYSTEM_PRODUCT_DETAILS: `/h5/ecosystem-product-details/:itemId`,
  APP_ECOSYSTEM_PRODUCT_PREORDER: `/h5/ecosystem-product-preorder`,

  // User Resouces
  MY_USER_RESOURCES: `${MYFF}/user-resources`,

  // Events
  BASE_EVENT: '/event',
  // Routes include whitelist; Others are for navigation (history.push())
  LANDING_EVENT_ROUTE: `/event/:eventName(${events})`,
  SCHEDULE_EVENT_ROUTE: `/event/:eventName(${events})/schedule`,
  CONFIRM_EVENT_ROUTE: `/event/:eventName(${events})/confirm`,
  RESCHEDULE_EVENT_ROUTE: `/event/:eventName(${events})/reschedule`,
  DEMO_DRIVE_ROUTE: `/event/demo-drive`,
  DEMO_DRIVE_ROUTE_SUCCESS: `/event/demo-drive/success`,
  LANDING_EVENT: '/event/:eventName',
  SCHEDULE_EVENT: '/event/:eventName/schedule',
  CONFIRM_EVENT: '/event/:eventName/confirm',
  RESCHEDULE_EVENT: '/event/:eventName/reschedule',

  FF91_PRODUCT_PAGE: '/ff-91/',
  FF91_PREORDER: '/ff-91',
  APP_FF91_PREORDER: '/h5/ff-91-preorder/',
  FF91_FUTURIST_PREORDER: '/ff-91-Futurist',
  APP_FF91_FUTURIST_PREORDER: '/h5/ff-91-Futurist-preorder/',

  toCN: '/cn/MyFF',
  toUS: '/us/MyFF',
  PRIVACY: '/us/privacy-policy',
  TERMS_OF_USE: '/us/terms-of-use',
  DEMO_DRIVE_WAIVER: 'https://genesis-cdn.ff.com/assets/pages/demo-drive/V.2+FF+Release+of+Liability+and+Waiver+Rights+July+2023.pdf',
  REZ_AGREEMENT: '/us/preorder-agreement',
  MAIL_PRODUCT: 'mailto:product@ff.com',
  REFERRAL_PROGRAM_TERMS: '/us/employee-referral-program-terms',
  REFERRAL_PROGRAM_LEARN_MORE: '/us/referral-learn-more/',
  REFERRAL_PROGRAM_AGREEMENT: '/us/referral-agreement/',
  ECOPRODUCT_AGREEMENT: '/us/aiHypercar-preorder-agreement',

  VERIFY_EMAIL: '/verify-email/:email',
  APP_RESERVE: '/h5/reserve',
  APP_LEARN_MORE: '/h5/reserve/learn-more',
  APP_USER_INFO: '/h5/reserve/user-info',
  APP_USER_AGREEMENT: '/h5/reserve/user-agreement',
  APP_PREORDER_AGREEMENT: '/h5/reserve/preorder-agreement',
  APP_ECOPRODUCT_AGREEMENT: '/h5/aiHypercar-preorder-agreement',
  APP_BILLING_INFO: '/h5/reserve/billing-info',
  APP_DELIVERY_CANCELLATION: '/h5/reserve/delivery-cancellation',

  APP_ORDER_DETAILS: '/h5/order-details/:confNum',
  APP_DESIGN_DETAILS: '/h5/design-details/:confNum',
  APP_FF91_STANDARD_PREORDER: '/us/h5/ff-91-preorder',

  // FF 91 Configurator
  FF91_CONFIGURATOR: '/us/design/2022/FF91/model',
  FF91_CONFIGURATOR_OPTIONS: '/us/design/2022/FF91/options',
  FF91_CONFIGURATOR_SUMMARY: '/us/design/2022/FF91/summary',
  FF91_STANDARD_PREORDER: '/us/preorder/ff-91',

  FF91_FUTURIST_PRODUCT: '/us/product/ff-91-Futurist/',
  FF91_FUTURIST_ALLIANCE_PRODUCT: '/us/product/ff-91-Alliance/',
};

PATHS.FF91_FUTURIST_PREORDER_FULL = `${PATHS.US_BASE}${PATHS.RESERVE}${PATHS.FF91_FUTURIST_PREORDER}`;
PATHS.APP_FF91_FUTURIST_PREORDER_FULL = `${PATHS.US_BASE}${PATHS.APP_FF91_FUTURIST_PREORDER}`;

export default PATHS;
