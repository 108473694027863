import query from '~/app/queries/user';
import { useQuery } from '@apollo/client';

export default function useUser() {
  const { data, ...rest } = useQuery(query);

  const user = data?.user;

  return {
    user,
    isAuthenticated: Boolean(user?.id),
    ...rest
  };
}
