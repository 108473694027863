function getEnv(key) {
  if (typeof window === 'undefined') return undefined;

  if(!window._env_) {
    console.warn('window._env_ is not defined');
    return undefined;
  }

  return window._env_[key];
}

export const ENABLE_NEW_REFERRAL = getEnv('REACT_APP_ENABLE_NEW_REFERRAL') === 'true';
export const ENABLE_NEW_ECOSYSTEM_PRODUCT = getEnv('REACT_APP_ENABLE_NEW_ECOSYSTEM_PRODUCT') === 'true';
export const ENABLE_FF91_PREORDER = getEnv('REACT_APP_ENABLE_FF91_PREORDER') === 'true';
export const REACT_APP_CDN_DOMAIN = getEnv('REACT_APP_CDN_DOMAIN');
export const ECOSYSTEM_PRODUCT_PREORDER_PRICE = getEnv('REACT_APP_ECOSYSTEM_PRODUCT_PREORDER_PRICE');
export const ENABLE_USER_RESOURCES = getEnv('REACT_APP_ENABLE_USER_RESOURCES') === 'true';
export const ENABLE_REFERRAL_PROGRAM = getEnv('REACT_APP_ENABLE_REFERRAL_PROGRAM') == 'true';
export const HIDE_VEHICLE_PRICE = getEnv('REACT_APP_HIDE_VEHICLE_PRICE') == 'true';
export const OPEN_REFERRAL_IN_APP = getEnv('REACT_APP_OPEN_REFERRAL_IN_APP') == 'true';
export const ENABLE_FX_SIMPLE_PREORDER = getEnv('REACT_APP_ENABLE_FX_SIMPLE_PREORDER') == 'true';
export const FX_SIMPLE_PREORDER_PRICE = getEnv('REACT_APP_FX_SIMPLE_PREORDER_PRICE');
