import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '~/app/containers/PrivateRoute';
import PATHS from '~/app/utils/paths';
import { getRegionPath } from '~/app/utils/pathUtils';
import RedirectPage from '~/app/components/RedirectPage';
import loadable from '@loadable/component';

const SignUp = loadable(() => import(/* webpackChunkName: "SignUp" */ '~/app/containers/Event/SignUp'));
const Schedule = loadable(() => import(/* webpackChunkName: "Schedule" */ '~/app/containers/Event/Schedule'));
const Confirmation = loadable(() => import(/* webpackChunkName: "Confirmation" */ '~/app/containers/Event/Confirmation'));
const DemoDrive = loadable(() => import(/* webpackChunkName: "DemoDrive" */ '~/app/containers/Event/DemoDrive'));
const DemoDriveSuccess = loadable(() => import(/* webpackChunkName: "DemoDrive" */ '~/app/containers/Event/DemoDrive/Success'));

const EventRouter = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={`/:locale(cn)${PATHS.BASE_EVENT}`} render={routeProps => (<RedirectPage {...routeProps} loc="/cn" />)} />
      <Route exact path={`/:locale(us)${PATHS.DEMO_DRIVE_ROUTE}`} component={DemoDrive} />
      <Route exact path={`/:locale(us)${PATHS.DEMO_DRIVE_ROUTE_SUCCESS}`} component={DemoDriveSuccess} />
      <Route exact path={`/:locale(us)${PATHS.LANDING_EVENT_ROUTE}`} component={SignUp} />
      <PrivateRoute exact path={getRegionPath(PATHS.SCHEDULE_EVENT_ROUTE)} component={Schedule} />
      <PrivateRoute exact path={getRegionPath(PATHS.RESCHEDULE_EVENT_ROUTE)} component={Schedule} />
      <PrivateRoute exact path={getRegionPath(PATHS.CONFIRM_EVENT_ROUTE)} component={Confirmation} />
      <Redirect to="/:locale(us|cn)/404" />
    </Switch>
  </React.Fragment>
);

export default EventRouter;
