import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHS from '~/app/utils/paths';

const ReferralRouter = () => (
  <Switch>
    <Route exact path={`/:locale(us)${PATHS.REFERRAL}`}>
      {
        ({ match }) => {
          window.location.href = `${PATHS.FF91_CONFIGURATOR}?REFERRALCODE=${match?.params?.referralCode}`;

          return null;
        }
      }
    </Route>
    <Redirect to="/:locale(us|cn)/404" />
  </Switch>
);

export default ReferralRouter;
