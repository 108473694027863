import CLIENT_ERROR_CODES from '@ff/constants/CLIENT_ERROR_CODES';

/**
 * Localized Error Codes
 * The error messages here are designed to be user-friendly.
 * So you would find many error codes are mapped to the same
 * message as the system details are considered to be user-irrelevant.
 * For debugging purpose, please refer to Core-API's error code
 * dictionary.
 */
export default CLIENT_ERROR_CODES;
