import React from 'react';
import injectSheet from 'react-jss';
import loadable from '@loadable/component';

const FFFooter = loadable(() => import('@ff/ui/Footer'));
const FFFooter2 = loadable(() => import('@ff/ui/Footer2'));
const FFFooter3 = loadable(() => import('@ff/ui/Footer3'));

class Footer extends React.Component {
  render() {
    let FooterVersion = FFFooter;

    if (['1', '2'].includes(this.props.version)) {
      FooterVersion = FFFooter;
    } else if (this.props.version == 3) {
      FooterVersion = FFFooter2;
    } else if (this.props.version == 4) {
      FooterVersion = FFFooter3;
    }

    return (
      <div className={this.props.classes.container}>
        <FooterVersion {...this.props} regions={this.props.version == 4 ? this.props.regionsV4 : this.props.regions} />
      </div>
    );
  }
}

const styles = {
  container: {
    '& a:hover': {
      color: 'white',
    }
  },
};

export default injectSheet(styles)(Footer);
