import React from 'react';
import AuthenticatedMenuBar from '../containers/Genesis/Menu/AuthenticatedMenuBar';
import menuProps from 'lib/menuProps';
import { styled } from '@ff/ui';
import Box from '@ff/ui/Box';
import { isAppOnly } from '~/app/utils/pathUtils';
import Icon from '@ff/ui/Icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MENU_PROPS = (window as any)?.MENU_PROPS || menuProps;

export default function ReferralLandingLayout({children}){
  const isApp = isAppOnly();

  return isApp
    ?
    <>
      <H5Header />
      <main>
        {children}
      </main>
    </>
    : 
    <>
      <AuthenticatedMenuBar
        {...MENU_PROPS}
        colorTheme='DARK'
        float={true}
        spacer={false}
        backgroundColor='transparent'
      />
      <main>
        {children}
      </main>
    </>
}

const H5Header = styled(Box)(({ theme }) => ({
  height: '46px',
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: theme.zIndices.menubar
}));