export default {
  // Sign Up page
  EVENT_BANNER_IMAGE: 'https://genesis-cdn.ff.com/test-ride/test-ride-banner-3-compressed.jpg',
  EVENT_BANNER_TEXT_EXPERIENCE: 'Experience the New Species: ',
  EVENT_BANNER_TEXT_FF91: 'FF 91 Futurist',
  EVENT_BANNER_SUBHEADER: 'Join the Futurist Product Officer Program and experience the future of mobility. Schedule a test ride now.',
  EVENT_NO_CODE: 'Test rides are not currently available without an invitation code. Fill out the form below and we will notify you when test rides become public.',
  EVENT_POLICY_HEADER: 'Test Ride Policy',
  EVENT_POLICY: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. At unde assumenda tenetur, mollitia corporis nostrum ex architecto accusamus. Atque dolorem iusto architecto quos eveniet blanditiis nihil doloribus impedit voluptatum fugit.',

  // Schedule/reschedule
  EVENT_GUEST_SELECT_1: 'As a registered test rider, you are welcome to bring up to ',
  EVENT_GUEST_SELECT_2: ' guest',
  EVENT_GUEST_SELECT_3: ' to this venue.',
  EVENT_SCHEDULE_HEADER: 'Schedule Your Test Ride',
  EVENT_SUMMARY_SCHEDULE: 'Test Ride Summary',
  EVENT_SUMMARY_RESCHEDULE: 'Rescheduled Test Ride Summary',

  // Confirmation
  EVENT_CONFIRMATION_HEADER: 'Test Ride Confirmation',
  EVENT_CHECK_INFO: 'Check your test ride information in'
};
