import React, { useEffect } from 'react';
import { styled } from '@ff/ui';
import Greeting from './Greeting';
import Sidebar from './Sidebar';
import Box from '@ff/ui/Box';
import zIndices from '~/app/utils/zIndices';

export default function SideBarLayout(props) {
  const { children } = props;

  // Update the mobile header background color to be solid
  useEffect(() => {
    const headerElement = document.getElementById('ff-ui-header');
    if (headerElement) {
      headerElement.style.backgroundColor = 'white';
    }
  }, []);

  return (
    <div>
      <SideBarContainer>
        {/* TODO:
          Calcuating the height of the navbar is buggy.
          Use hard coded value for now.
        */}
        <SideBarPosition navHeight={46}>
          <Nav>
            <Greeting />
            <Sidebar />
          </Nav>
        </SideBarPosition>
        {children}
      </SideBarContainer>
    </div>
  );
}

const SideBarPosition = styled(Box)(({ theme, navHeight }) => ({
  height: '50px',
  position: 'sticky',
  top: navHeight ? (navHeight - 1) : 0,
  zIndex: zIndices['sidebar'],
  marginLeft: '-10px',
  marginRight: '-10px',
  // 480px - 600px is a breakpoint in antd which we dont have
  [theme.mediaQueries.minWidth('480px')]: {
    marginLeft: '-36px',
    marginRight: '-36px',
  },
  [theme.mediaQueries.sm]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [theme.mediaQueries.md]: {
    height: '100%',
    position: 'relative',
    top: 0,
  },
}));

const SideBarContainer = styled.Box(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.mediaQueries.md]: {
    flexDirection: 'row'
  },
}));
SideBarContainer.displayName = 'SideBarContainer';

const Nav = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderBottom: '1px solid rgba(0, 0, 0, 10%)',
  boxShadow: '0 3px 6px -7px #222',
  flexDirection: 'row',
  background: 'white',
  width: '105%',
  maxWidth: '100%',
  overflow: 'hidden',
  paddingLeft: '10px',
  [theme.mediaQueries.sm]: {
    paddingLeft: 0,
  },
  [theme.mediaQueries.md]: {
    border: 'none',
    boxShadow: 'none',
    marginLeft: 0,
    paddingRight: 0,
    position: 'relative',
    flexDirection: 'column',
  }
}));
