import translations from './locales';
import eventProps from './eventProps';

/**
 * Isomorphic gettext
 * @param {String} key key of string.
 * @return {String}
 */
export default function gettext(key, props={}) {
  const trans = translations[key];
  let lang = 'en';
  let region;

  if (typeof window !== 'undefined') {
    region = window.location.href.split('/')[3].toUpperCase();
  }

  switch (region) {
    case 'CN':
      lang = 'zh';
      break;
    default:
      lang = 'en';
      break;
  }

  if (!trans) {
    return 'Server Error.';
  }
  var res = trans[lang];
  for(const key in props) {
    res = res.replace( `@${key}@`, props[key]);
  }
  return res;
}

// Get prop for the specified event
export const getEventProps = (key) => {
  let eventType = 'test-ride';

  if (typeof window !== 'undefined') {
    const name = window.location.href.split('/')[5].toLowerCase();
    eventType = name || eventType;
  }

  const prop = eventProps[eventType] || eventProps['test-ride'];

  return prop[key];
};
