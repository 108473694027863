import React, { useEffect } from 'react';

const RedirectPage = ({ loc }) => {

  useEffect(() => {
    window.location = loc;
    return () => { };
  }, [loc]);

  return (<section>Redirecting... to {loc}</section>);
};

export default RedirectPage;
