import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import App from '~/app/containers/App';

import 'normalize.css';
// Bring customized styles after 'App' to override ant defaults
import './app/style/index.less';
import { DefaultThemeProvider } from '@ff/ui';
import zIndices from './app/utils/zIndices';
import ErrorBoundary from './app/components/ErrorBoundary';
import { SharedConfigContextProvider } from './shared/contexts/SharedConfigContext';

type Headers = {
  region: 'US',
  source: 'Web',
  'order-version'?: '1' | '2' | '3',
  'customer-version'?: '1' | '2',
  'referral-version'?: '1' | '2',
  'configuration-version'?: '1' | '2',
}

const headers: Headers = {
  region: 'US',
  source: 'Web',
  'order-version': '3',
  'customer-version': '2',
  'referral-version': '2',
  'configuration-version': '2',
}

const client = new ApolloClient({
  link: createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
    headers
  }),
  cache: new InMemoryCache(),
});

/* =============================================================================
=    Apollo
============================================================================= */
const app = (
  <ErrorBoundary>
    <DefaultThemeProvider
      zIndices={{
        modal: zIndices['modal'],
        tooltip: zIndices['tooltip'],
        menubar: zIndices['menubar'],
        menubarDrawer: zIndices['menubarDrawer'],
        dropdown: zIndices['dropdown']
      }}
    >
      <SharedConfigContextProvider isApp={false}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </SharedConfigContextProvider>
    </DefaultThemeProvider>
  </ErrorBoundary>
);
const rootElement = document.getElementById('app');

ReactDOM.render(app, rootElement);
