import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from '@ff/ui';
import { getSameRegionPath as rp } from '~/app/utils/pathUtils';
import PATHS from '~/app/utils/paths';
import Item from '~/app/layouts/SideBarLayout/Sidebar/Item';
import Box from '@ff/ui/Box';
import { ENABLE_NEW_ECOSYSTEM_PRODUCT, ENABLE_USER_RESOURCES, ENABLE_REFERRAL_PROGRAM } from '~/app/settings';
import loadable from '@loadable/component';

const MuiContainer = loadable(() => import(/* webpackChunkName: "MobileNav" */ '@ff/ui/utilComponents/MuiContainer'));
const MobileNav = loadable(() => import(/* webpackChunkName: "MobileNav" */ './MobileNav'));

const primaryOptions = [
  // values need to match key in PATHS
  { value: 'MY_FF', text: 'My Vehicle Collection', icon: 'ff-icon-vehicle_collection_2', enable: true, iconStyles: {} },
  { value: 'MY_ECOSYSTEM_PRODUCT', text: 'FF aiHypercar+', icon: 'ff-icon-ecosystem_product', enable: ENABLE_NEW_ECOSYSTEM_PRODUCT, iconStyles: {} },
  { value: '', text: 'Service', icon: 'ff-icon-service', iconStyles: {}, enable: false },
  {
    value: 'MY_EVENTS', text: 'Events', icon: 'ff-icon-events_2', enable: true,
    iconStyles: {
      fontSize: '19px',
    },
  },
  { value: 'MY_FF_POINTS', text: 'Futurist Co-creation Points', icon: 'ff-icon-ff_points', enable: true, iconStyles: {} },
  {
    value: 'MY_REFERRALS', text: 'Referral Program', icon: 'ff-icon-referral_2', enable: ENABLE_REFERRAL_PROGRAM,
    iconStyles: {
      fontSize: '23px',
    },
  },
  { value: 'MY_USER_RESOURCES', text: 'User Resources', icon: 'ff-icon-cube', enable: ENABLE_USER_RESOURCES, iconStyles: {} },
  { value: 'MY_ACCOUNT', text: 'Account Info', icon: 'ff-icon-account_info', enable: true,
    iconStyles: {
      fontSize: '23px'
    },
  },
];

// Bottom section
const secondaryOptions = [
  {
    value: 'LOGOUT',
    text: 'Sign Out',
    icon: 'ff-icon-sign_out',
    enable: true,
    iconStyles: {
      fontSize: '22px',
      marginLeft: '-3px',
      marginRight: '3px',
    },
  },
]

export default function Sidebar() {
  const history = useHistory();
  const location = useLocation();

  const [current, setCurrent] = useState('');

  useEffect(() => {
    const currentNav = getCurrentNavFromPath(location.pathname);

    setCurrent(currentNav);

  }, [location.pathname]);

  const handleClick = (e, key) => {
    // Check if the path is a valid route.
    if (PATHS[key] === undefined) {
      // Send the user back to the MY_FF dashboard.
      return history.push(rp(PATHS.MY_FF));
    }
    // Send the user to the appropriate path
    history.push(rp(PATHS[key]));
  };

  const handleKeyPress = (e, value) => {
    if (e.key === 'Enter') {
      handleClick(e, value);
    }
  };

  return (
    <Container>
      <SidebarContent>
        {/* Mobile */}
        <MuiContainer>
          <MobileStyledNav>
            <MobileNav
              primaryOptions={primaryOptions}
              secondaryOptions={secondaryOptions}
              setCurrent={setCurrent}
              current={current}
              onHandleClick={handleClick}
            />
          </MobileStyledNav>
        </MuiContainer>

        {/* Desktop */}
        <DesktopStyledNav>
          <SideBarList>
            {primaryOptions.map((element, index) => (
              (element.enable) && (
                <SideBarListItem
                  tabIndex="0"
                  key={index}
                  active={element.value === current}
                  onClick={(e) => handleClick(e, element.value)}
                  onKeyPress={(e) => handleKeyPress(e, element.value)}
                >
                  <Item
                    icon={element.icon}
                    iconStyles={element.iconStyles}
                    text={element.text}
                    active={element.value === current}
                  />
                </SideBarListItem>
              )
            ))}
            <Box
              borderLeft={{ _: '1px solid rgba(124, 135, 151, 0.2)', md: 'none' }}
              borderBottom={{ _: 'none', md: '1px solid rgba(124, 135, 151, 0.2)' }}
              marginY="8px" // Combined with item padding is roughly 26px
            />
            {secondaryOptions.map((element) => (
              (element.enable) && (
                <SideBarListItem
                  tabIndex="0"
                  key={element.value}
                  active={element.value === current}
                  onClick={(e) => handleClick(e, element.value)}
                  onKeyPress={(e) => handleKeyPress(e, element.value)}
                >
                  <Item
                    icon={element.icon}
                    iconStyles={element.iconStyles}
                    text={element.text}
                    active={element.value === current}
                  />
                </SideBarListItem>
              )
            ))}
          </SideBarList>
        </DesktopStyledNav>
      </SidebarContent>
    </Container>
  );
}

function getCurrentNavFromPath(pathname) {
  // Account
  if (pathname.includes('account')) {
    return 'MY_ACCOUNT';
  }
  // Design
  if (pathname.includes('design')) {
    // no design tab yet
    return 'MY_FF';
  }
  // Event
  if (pathname.includes('event')) {
    return 'MY_EVENTS';
  }
  // Referrals
  if (pathname.includes('referrals')) {
    return 'MY_REFERRALS';
  }
  // EcoSystem Product
  if (pathname.includes('ecosystem-product')) {
    return 'MY_ECOSYSTEM_PRODUCT';
  }
  // User Resources
  if (pathname.includes('user-resources')) {
    return 'MY_USER_RESOURCES';
  }
  // FF Points
  if (pathname.includes('points')) {
    return 'MY_FF_POINTS';
  }

  // MyFF
  return 'MY_FF';
}

const Container = styled.Box(({ theme }) => ({
  marginRight: '5px',
  overflowX: 'auto',
  width: '100%',
  '::-webkit-scrollbar': {
    height: 0,
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(23, 26, 32, 0.19)',
  },
  [theme.mediaQueries.md]: {
    marginRight: 0,
    maxWidth: '362px',
    overflow: 'hidden',
    paddingLeft: 'none',
    '::-webkit-scrollbar': {
      height: '2px',
    },
  },
}));
Container.displayName = 'Container';

const SidebarContent = styled.Box(({ theme }) => ({
  height: '100%',
  [theme.mediaQueries.md]: {
    display: 'block',
    height: '100vh',
    // Left margin (22) combined with antd margin (24) matches right margin (46)
    margin: '32px 46px 32px 22px',
  },
}));
SidebarContent.displayName = 'SidebarContent';

const SideBarList = styled.Box(({ theme }) => ({
  height: '100%',
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'row',
  gap: '25px',
  [theme.mediaQueries.md]: {
    flexDirection: 'column',
    gap: '4px',
  },
}));
SideBarList.defaultProps = { as: 'ul' };
SideBarList.displayName = 'SideBarList';

const SideBarListItem = styled.Box(({ theme, active }) => ({
  marginLeft: 0,
  width: '270px',
  padding: '3px',
  // figma -> background: 'rgba(0, 0, 0, 0.05)',
  // Prefer non-alpha version for background colors
  backgroundColor: 'transparent',
  transition: 'all 0.3s ease',
  [theme.mediaQueries.md]: {
    backgroundColor: active ? 'rgb(239, 239, 239)' : 'transparent',
  },
}));
SideBarListItem.defaultProps = { as: 'li' };
SideBarListItem.displayName = 'SideBarListItem';

const MobileStyledNav = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.mediaQueries.md]: {
    display: 'none',
  }
}));

const DesktopStyledNav = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.mediaQueries.md]: {
    display: 'block',
  }
}));
