import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PATHS from '~/app/utils/paths';
import { getRegionPath } from '~/app/utils/pathUtils';
import loadable from '@loadable/component';

const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ '~/app/pages/LoginPage'));
const ForgotPasswordPage = loadable(() => import(/* webpackChunkName: "ForgotPasswordPage" */ '~/app/pages/ForgotPasswordPage'));
const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "ResetPasswordPage" */ '~/app/pages/ResetPasswordPage'));
const SignOutPage = loadable(() => import(/* webpackChunkName: "SignOutPage" */ '~/app/pages/SignOutPage'));

// TODO: Rename "New"
const NewAccountRouter = () => (
  <Switch>
    <Route exact path={getRegionPath(PATHS.LOGIN)} component={LoginPage} />
    <Route exact path={getRegionPath(PATHS.FORGOT_PASS)} component={ForgotPasswordPage} />
    <Route exact path={getRegionPath(PATHS.RESET_PASS)} component={ResetPasswordPage} />
    <Route exact path={getRegionPath(PATHS.LOGOUT)} component={SignOutPage} />
    {/* Redirect for legacy My Account - TODO: remove at some point */}
    <Route exact path={getRegionPath(PATHS.ACCOUNT)}>
      <Redirect to={getRegionPath(PATHS.MY_FF)} />
    </Route>
    <Redirect to="/:locale(us|cn)/404" />
  </Switch>
);

export default NewAccountRouter;
