function trackUIEvent(action, label, value, isApp) {
  window?.dataLayer?.push({
    event: 'UI Events',
    action,
    label,
    value,
    source: isApp ? 'app' : 'web'
  });
}

function bookingSuccess({ withEmail, confirmationNum, isApp }) {
  const label = withEmail ? 'Success with Email Sign up' : 'Success without Email Sign up';
  const price = 0;

  trackUIEvent(`Booking Success - ${confirmationNum}`, label, price, isApp);
}

function bookingError({ error, isApp }) {
  trackUIEvent('Standard Reservation Form Error', error, undefined, isApp);
}

function reserveSuccess({ withEmail, confirmationNum, price, isApp }) {
  const label = withEmail ? 'Success with Email Sign up' : 'Success without Email Sign up';

  trackUIEvent(`Reserve Form Success - ${confirmationNum}`, label, price, isApp);
}

function reserveError({ error, isApp }) {
  trackUIEvent('Priority Reservation Form Error', error, undefined, isApp);
}

function paidReservationFormSelected() {
  trackUIEvent('Paid Reservation Form Selected', 'Reservation Form', undefined, false);
}

function standardReservationFormSelected() {
  trackUIEvent('Standard Reservation Form Selected', 'Reservation Form', undefined, false);
}

export default {
  bookingSuccess,
  bookingError,
  reserveSuccess,
  reserveError,
  paidReservationFormSelected,
  standardReservationFormSelected
};

export {
  trackUIEvent
};
