import React from 'react';
import useUser from '~/app/hooks/queries/useUser';
import MenuBar from './MenuBar';

export default function AuthenticatedMenuBar(props) {
  const { isAuthenticated, loading } = useUser();

  return (
    <MenuBar {...props} isLoggedIn={isAuthenticated} isFetchingUser={loading} />
  );
}
