const menuProps = {
  scroll_effect: true,
  logo_link: '/us/',
  first_set: [
    {
      url: '/us/ff-91',
      label: 'FF 91'
    },
    {
      url: '/us/configurator',
      label: 'DESIGN YOURS'
    },
    {
      url: '/us/mobile-app/',
      label: 'APP'
    },
    {
      url: 'https://investors.ff.com/',
      label: 'INVESTORS'
    }
  ],
  second_set: [
    {
      url: '/us/MyFF/',
      label: 'SIGN IN',
      loggedInLabel: 'My FF'
    }
  ],
  hero_button: {
    url: '/us/preorder',
    label: 'RESERVE'
  }
};

export default menuProps;
