import { useReducer } from 'react';

export default function useSimpleReducer(initialState){
  return useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    initialState,
  );
}
