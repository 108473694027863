import React from 'react';

const SharedConfigContext = React.createContext({ isApp: false });

interface Props {
  children: React.ReactNode;
  isApp?: boolean;
}

const SharedConfigContextProvider = (props: Props) => {
  const { children, isApp = false } = props;

  return (
    <SharedConfigContext.Provider value={{ isApp }}>
      {children}
    </SharedConfigContext.Provider>
  );
}

export { SharedConfigContext, SharedConfigContextProvider };
