// const usSubscribeProps = {
//   color_theme: 'DARK',
//   scroll_in_animation: true,
//   region: 'US',
//   expandable: true,
//   title: 'Get the latest news and important updates from FF',
//   form_fields: {
//     email: 'Email Address',
//     first_name: 'First Name',
//     last_name: 'Last Name',
//     zipcode: 'Zipcode',
//     interested_in_reserve: 'I\'m interested in reserving an FF 91',
//     reserve_now: 'Reserve Now',
//     subscribe: 'Subscribe',
//     error_msg: 'Something went wrong. Please try again later.',
//     subscribe_in_china: 'Please subscribe on our China region site.',
//     success_message: 'Your subscription has been confirmed. You’ll receive the latest news and updates from Faraday Future.',
//     continue_to_reserve: 'If you would like to reserve an FF 91 please click the button below.'
//   },
//   redirect_link: '/cn'
// };

const usFooterProps = {
  footerRegion: 'US',
  regions: [
    {
      imgUrl: 'https://genesis-cdn.ff.com/icon-en.png',
      value: 'US',
      domain: 'https://www.ff.com/us/'
    },
    {
      imgUrl: 'https://genesis-cdn.ff.com/icon-china.png',
      value: 'CN',
      domain: 'https://www.faradayfuturecn.com/cn/'
    }
  ],
  items: [
    {
      url: 'https://www.ff.com/us/sitemap',
      label: 'Sitemap',
      sectionTitle: 'ABOUT US',
      links: [
        {
          url: '/us/our-company',
          label: 'Our Company'
        },
        {
          url: '/us/our-approach',
          label: 'Our Approach'
        },
        {
          url: '/us/our-team',
          label: 'Our Team'
        },
        {
          url: '/us/co-creation',
          label: 'Co-Creation'
        },
        {
          url: '/us/press-room',
          label: 'Press Room'
        },
        {
          url: '/us/futuresight',
          label: 'Blog'
        },
        {
          url: '/us/solutions',
          label: 'Solutions'
        },
        {
          url: 'https://investors.ff.com',
          label: 'Investors'
        },
        {
          url: '/us/ff-zero-1',
          label: 'FF Zero 1'
        },
        {
          url: '/us/gallery',
          label: 'FF Gallery'
        }
      ]
    },
    {
      url: 'https://www.ff.com/us/privacy-policy',
      label: 'Privacy Policy',
      sectionTitle: 'SUPPORT',
      links: [
        {
          url: '/us/contact-us',
          label: 'Contact Us'
        },
        {
          url: '/us/frequently-asked-questions',
          label: 'FAQ'
        },
        {
          url: '/us/careers',
          label: 'Careers'
        },
        {
          url: '/us/sitemap',
          label: 'Sitemap'
        },
        {
          url: '/us/privacy-policy',
          label: 'Privacy Policy'
        },
        {
          url: '/us/terms-of-use',
          label: 'Terms of Use'
        }
      ]
    }
  ],
  socialLinks: [
    {
      url: 'https://www.linkedin.com/company/faradayfuture',
      icon: 'ff-icon-social-linkedin'
    },
    {
      url: 'https://www.youtube.com/c/faradayfuture',
      icon: 'ff-icon-social-youtube'
    },
    {
      url: 'https://twitter.com/faradayfuture?lang=en',
      icon: 'ff-icon-social-twitter'
    },
    {
      url: 'https://www.facebook.com/faradayfuture/',
      icon: 'ff-icon-social-facebook'
    },
    {
      url: 'https://www.instagram.com/faradayfuture/',
      icon: 'ff-icon-social-instagram'
    }
  ],
  copyright: {
    text: '© {year} Faraday&Future Inc. The Faraday Future name and logo are trademarks of Faraday&Future Inc. All rights reserved.'
  },
  download: {
    url: 'https://www.ff.com/us/mobile-app',
    title: 'DOWNLOAD FF INTELLIGENT APP',
    iosStoreLink: 'https://itunes.apple.com/us/app/id1454187098',
    iosAppLink: 'https://faradayfuture.app.link',
    androidStoreLink: 'https://play.google.com/store/apps/details?id=com.faradayfuture.online',
    androidAppLink: 'faradayfuture://feed',
    appButtonText: 'INSTALL APP'
  },
  subscribeForm: {
    enabled: true,
    submitUrl: '/api/subscribe',
    title: 'Get the latest news and important updates from FF',
    subtitle: 'Sign up for the latest of the FF 91 Futurist and other news about Faraday Future',
    inputPlaceholder: 'Email Address *',
    inputErrorMessage: '* Please enter a valid email address',
    serverErrorMessage: 'Something went wrong. Please try again later.',
    successMessage: 'Your subscription has been confirmed. You’ll receive the latest news and updates from Faraday Future.',
    button: 'SUBSCRIBE →',
    margin: '10px 0px'
  }
};

export default usFooterProps;
