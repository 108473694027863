import { styled } from '@ff/ui';
import Box from '@ff/ui/Box';
import FFUILink from '@ff/ui/Link';
import { Link as ReactRouterLink } from 'react-router-dom';

const BaseTextStyle = styled(Box)(({ theme }) => ({
  color: theme.colors.text02,
}));

// SOP v2.0.1 Elements

// Header1
// Header2
// Header3

// Display1

// Label1
// Label2

// Paragraph1
// Paragraph2

export const Header1 = styled(BaseTextStyle)({
  fontWeight: 400,
  fontSize: '40px',
  lineHeight: '47px',
  letterSpacing: '-0.01em'
});
Header1.displayName = 'Header1';
Header1.defaultProps = { as: 'h1' };

export const Header2 = styled(BaseTextStyle)({
  fontWeight: 500,
  fontSize: '17px',
  lineHeight: '24px',
  letterSpacing: '0.006em'
});
Header2.displayName = 'Header2';
Header2.defaultProps = { as: 'h2' };

export const Header2Link = styled(FFUILink)(({ theme }) => ({
  color: theme.colors.text02,
  fontWeight: 500,
  fontSize: '17px',
  lineHeight: '24px',
  letterSpacing: '0.006em'
}));
Header2.displayName = 'Header2';
Header2.defaultProps = { as: 'h2' };

export const Header3 = styled(BaseTextStyle)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  letterSpacing: '0.006em'
});
Header3.displayName = 'Header3';
Header3.defaultProps = { as: 'h3' };

export const Display1 = styled(BaseTextStyle)({
  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '36px',
});
Display1.displayName = 'Display1';

export const Display2 = styled(BaseTextStyle)({
  fontWeight: 500,
  fontSize: '30px',
  lineHeight: '28px',
  letterSpacing: '-0.01em',
});
Display2.displayName = 'Display2';

export const Label1 = styled(BaseTextStyle)(({ theme }) => ({
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '28px',
  color: theme.colors.text03,
}));
Label1.displayName = 'Label1';

export const Label2 = styled(BaseTextStyle)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
}));
Label2.displayName = 'Label2';

export const Label2Light = styled(Label2)(({ theme }) => ({
  fontWeight: 300,
  color: theme.colors.text03,
}));
Label2Light.displayName = 'Label2Light';

// Paragraph1 - reserved

export const Paragraph2 = styled(BaseTextStyle)({
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '28px',
});
Paragraph2.displayName = 'Paragraph2';

export const Paragraph2Lighter = styled(Paragraph2)(({ theme }) => ({
  color: theme.colors.text04
}));
Paragraph2Lighter.displayName = 'Paragraph2Lighter';

export const Paragraph3 = styled(BaseTextStyle)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16.59px',
});
Paragraph3.displayName = 'Paragraph3';

export const Paragraph3Link = styled(FFUILink)(({ theme }) => ({
  color: theme.colors.text02,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16.59px',
}));
Paragraph3Link.displayName = 'Paragraph3Link';

export const Paragraph3Light = styled(Paragraph3)(({ theme }) => ({
  color: theme.colors.text04,
}));
Paragraph3Light.displayName = 'Paragraph3Light';

export const Paragraph3Lighter = styled(Paragraph3)(({ theme }) => ({
  color: theme.colors.text03,
}));
Paragraph3Lighter.displayName = 'Paragraph3Lighter';

export const Paragraph3LightInternalLink = styled(ReactRouterLink)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16.59px',
  color: theme.colors.text04,
}));
Paragraph3LightInternalLink.displayName = 'Paragraph3LightInternalLink';

export const Paragraph4 = styled(BaseTextStyle)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '24px',
  color: theme.colors.text04,
}));
Paragraph4.displayName = 'Paragraph2';

// Modal Scrollbar Styles
export const ModalScrollbarStyles = styled(Box)({
  scrollbarColor: 'rgba(23, 26, 32, 0.19)',
  '::-webkit-scrollbar': {
    width: '14px',
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(23, 26, 32, 0.19)',
    backgroundClip: 'padding-box',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
  },
});
