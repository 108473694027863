import React from 'react';
import { styled } from '@ff/ui';  
import FadeIn from '@ff/ui/Animation/FadeIn';
import useUser from '~/app/hooks/queries/useUser';
import userProfileSVG from './user_profile.svg';
import Box from '@ff/ui/Box';
import { Paragraph2Lighter } from '~/app/utils/typography';

// Fade-in user's name, if available. default to non-collapsible whitespace
export default function Greeting() {
  const { user } = useUser();

  return (
    <Container>
      <UserProfileSVG
        // Align with the sidebar items
        marginLeft={{ _: 0, md: '30px' }}
        backgroundColor="black"
        height="44px"
        width="44px"
        image={userProfileSVG}
      />
      {/* User First Name */}
      {
        user?.firstName ?
          <FadeIn duration={0.5}>
            <FirstName marginLeft="13px">
              {`${user.firstName}`}
            </FirstName>
          </FadeIn> : <>&nbsp;</>
      }
    </Container>
  );
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  // Border
  '&::after': {
    content: `""`,
    display: 'block',
    margin: '0 auto',
    paddingLeft: '10px',
    paddingTop: '25px',
    borderRight: '1px solid #F4F4F4',
    [theme.mediaQueries.md]: {
      content: 'none',
    },
  },
  [theme.mediaQueries.md]: {
    height: '100px',
    border: '1px solid #F4F4F4',
  },
}));

const UserProfileSVG = styled(Box)(({ theme, image }) => ({
  maskImage: `url(${image})`,
  maskRepeat: 'no-repeat',
  maskPosition: 'center',
  maskSize: '30px',
  [theme.mediaQueries.md]: {
    maskSize: '44px',
  },
}));

const FirstName = styled(Paragraph2Lighter)(({ theme }) => ({
  display: 'none',
  [theme.mediaQueries.md]: {
    display: 'block',
  },
}));