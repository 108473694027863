import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHS from '~/app/utils/paths';
import { ENABLE_FF91_PREORDER } from '~/app/settings';
import loadable from '@loadable/component';

const FF91PreorderPage = loadable(() => import(/* webpackChunkName: "FF91PreorderPage" */ '~/app/pages/FF91PreorderPage'));

const ReserveRouter = () => (
  <Switch>
    {ENABLE_FF91_PREORDER && (
      <>
        <Route exact path={`/:locale(us)${PATHS.RESERVE}${PATHS.FF91_PREORDER}`} component={FF91PreorderPage} />
        <Route exact path={`/:locale(us)${PATHS.RESERVE}${PATHS.FF91_FUTURIST_PREORDER}`} >
          <FF91PreorderPage modelCode='FF91FNA' />
        </Route>
      </>
    )}
    <Redirect to="/:locale(us|cn)/404" />
  </Switch>
);

export default ReserveRouter;
