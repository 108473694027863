import React, { useCallback, useContext } from 'react';
import useSimpleReducer from '~/app/hooks/useSimpleReducer';
import { isAppOnly } from '~/app/utils/pathUtils';

const defaultConfig = {
  showMenuBar: !isAppOnly(),
  showFooter: !isAppOnly(),
};

export const ConfigContext = React.createContext({
  ...defaultConfig,
  setShowMenuBar: () => { },
  setShowFooter: () => { }
});

export default function ConfigContextProvider(props) {
  const { children } = props;

  const [state, setState] = useSimpleReducer(defaultConfig);

  const setShowMenuBar = useCallback((showMenuBar) => setState({ showMenuBar }), [setState]);
  const setShowFooter = useCallback((showFooter) => setState({ showFooter }), [setState]);

  return (
    <ConfigContext.Provider value={{
      ...state,
      setShowMenuBar,
      setShowFooter
    }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  return useContext(ConfigContext)
}
