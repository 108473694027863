import PATHS from './paths';

const REGIONS = {
  US: PATHS.US_BASE,
  CN: PATHS.CN_BASE
};

// Returns a path prefixed by a designated region, default US.
function getRegionPath(path, region) {
  if (region === REGIONS.CN) {
    return `${PATHS.CN_BASE}${path}`;
  }

  return `${PATHS.US_BASE}${path}`;
}

// Returns a path in the same region as the current url.
// Replace the dynamic params with provided values, if applicable
function getSameRegionPath(path, params, qsParams) {
  // pathname gives 'us/path' from 'http://example.com/us/path'
  const { pathname } = window.location;
  const pathArray = pathname.split('/');
  let prefix;

  if (pathArray.length > 0) {
    prefix = `/${pathArray[0]}`;
  }
  if (!Object.values(REGIONS).includes(prefix)) {
    prefix = PATHS.US_BASE;
  }

  let regionPath = `${prefix}${path}`;

  if (params) {
    Object.keys(params).forEach((k) => {
      regionPath = regionPath.replace(`:${k}`, params[k]);
    });
  }

  if(qsParams){
    regionPath += '?';
    Object.keys(qsParams).forEach((k) => {
      if(qsParams[k] !== undefined && qsParams[k] !== null){
        regionPath += `${k}=${qsParams[k]}&`;
      }
    });
    regionPath = regionPath.slice(0, regionPath.length - 1); 
  }

  return regionPath;
}

function isAppOnly() {
  if (typeof window === 'undefined') return false;

  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get('APPONLY') === '1';
}

export {
  getRegionPath,
  getSameRegionPath,
  isAppOnly,
  REGIONS
};
