import errorCode from './localizedErrorCodes';
import { FF91_TEXT } from './constants';

export default {
  ...errorCode,
  LABEL_TITLE_DEFAULT: {
    en: "Experience a New Species | Faraday Future",
    zh: ""
  },
  LABEL_TITLE_RESERVATION: {
    en: "FF-91 Preorder",
    zh: ""
  },
  LABEL_LOGOUT: {
    en: 'Log Out',
    zh: '退出账号'
  },
  LABEL_LOGIN: {
    en: 'Log In',
    zh: '登录'
  },
  LABEL_REGISTER: {
    en: 'Register',
    zh: '注册'
  },
  MESSAGE_LOGIN_IN_US: {
    en: 'You are logging in to the US site. Not from the United States? ',
    zh: '您正在登录美国站。拥有中国账户？'
  },
  LABEL_CLICK_HERE: {
    en: 'Click Here',
    zh: '点击这里'
  },
  MESSAGE_SIGN_UP: {
    en: 'Creating an account is the first step on the journey to becoming one of the first to own our flagship vehicle. Register to be contacted about company news and invitations to exclusive events. If you have already created an account, log in to update and view your account information.',
    zh: '创建FF账户，踏上成为首批旗舰车尊享车主的奇妙旅程。FF团队会向您发送公司新闻，邀请您出席独家活动。Faraday Future，开启生活新世界。'
  },
  MESSAGE_PHONE_NUMBER_VALID: {
    en: 'Please enter a valid phone number',
    zh: '请输入正确的电话号码'
  },
  MESSAGE_INVITATION_CODE_VALID: {
    en: 'Please enter a valid invitation code',
    zh: ''
  },
  LABEL_EMAIL: {
    en: 'Email',
    zh: '电子邮件'
  },
  LABEL_OLD_EMAIL: {
    en: 'Current Email',
    zh: ''
  },
  LABEL_NEW_EMAIL: {
    en: 'New Email',
    zh: ''
  },
  LABEL_CONFIRM_EMAIL: {
    en: "Confirm Email",
    zh: ''
  },
  ERROR_VALID_EMAIL: {
    en: 'The input is not a valid email!',
    zh: '您输入的电子邮箱不存在。'
  },
  ERROR_EMPTY_EMAIL: {
    en: 'Please enter your email!',
    zh: '请输入您的电子邮箱'
  },
  ERROR_SAME_EDIT_EMAIL: {
    en: 'New email is the same as current email!',
    zh: ''
  },
  ERROR_EMAIL_IN_USE: {
    en: 'Email is already in use',
    zh: ''
  },
  ASSOCIATED_EMAIL_ADDRESS: {
    en: 'An account is associated with this email.',
    zh: '帐户与此电子邮件相关联。'
  },
  ASSOCIATED_EMAIL_ADDRESS_CONTINUED: {
    en: 'to continue.',
    zh: '继续'
  },
  LABEL_PASSWORD: {
    en: 'Password',
    zh: '密码'
  },
  ERROR_EMPTY_PASSWORD: {
    en: 'Please input your Password!',
    zh: ''
  },
  ERROR_SAME_EDIT_PASSWORD: {
    en: 'New password is the same as current password',
    zh: ''
  },
  ERROR_VERIFICATION_CODE_FORMAT: {
    en: 'Invalid verification code, please apply a new verification code',
    zh: '无效的验证码，请申请一个新的验证码'
  },
  VERIFICATION_CODE: {
    en: 'Verification Code',
    zh: '短信验证码'
  },
  SEND_VERIFICATION_CODE: {
    en: 'Send Verification Code',
    zh: '发送验证码'
  },
  VERIFY_EMAIL: {
    en: 'Verify Email',
    zh: '验证电邮'
  },
  RESEND_VERIFICATION_CODE: {
    en: 'Resend',
    zh: '再次发送'
  },
  LABEL_FORGOT_PASSWORD: {
    en: 'FORGOT PASSWORD',
    zh: '忘记密码'
  },
  LABEL_EDIT_ACCOUNT: {
    en: 'EDIT ACCOUNT',
    zh: ''
  },
  LABEL_CHANGE_PASSWORD: {
    en: 'Change Password',
    zh: ''
  },
  LABEL_CHANGE_EMAIL: {
    en: 'Change Email',
    zh: ''
  },
  LABEL_CANCEL_TEST_RIDE: {
    en: 'Cancel Test Ride',
    zh: ''
  },
  LABEL_ARE_YOU_SURE: {
    en: 'Are you sure?',
    zh: ''
  },
  LABEL_YES_CANCEL: {
    en: 'Yes, Cancel',
    zh: ''
  },
  LABEL_YES: {
    en: 'Yes',
    zh: ''
  },
  LABEL_NO: {
    en: 'No',
    zh: ''
  },
  MESSAGE_TEST_RIDE_WAITLIST_TITLE: {
    en: 'Oops!',
    zh: ''
  },
  MESSAGE_TEST_RIDE_WAITLIST_1: {
    en: 'It looks like some earlier birds took all the worms.',
    zh: ''
  },
  MESSAGE_TEST_RIDE_WAITLIST_2: {
    en: 'Join the waiting list and we\'ll inform you when a spot is available.',
    zh: ''
  },
  MESSAGE_TEST_RIDE_WAITLIST_CONFIRMATION: {
    en: 'Thank you for joining the waitlist. We will contact you in order of enrollment to set up an appointment.',
    zh: ''
  },
  LABEL_TEST_RIDE_JOIN_WAITLIST: {
    en: 'Join the waitlist',
    zh: ''
  },
  LABEL_TEST_RIDE_PASS_WAITLIST: {
    en: 'Maybe later',
    zh: ''
  },
  MESSAGE_CANCEL_TEST_RIDE: {
    en: 'This cancellation cannot be undone.',
    zh: ''
  },
  MESSAGE_UPDATE_GUEST_INFO: {
    en: 'If you want to change the guest info, please call us at (800) 228-7702 or email us at product@ff.com. We\'ll be right here to support.',
    zh: ''
  },
  LABEL_CONTINUE: {
    en: 'Continue',
    zh: ''
  },
  LABEL_CLOSE: {
    en: 'Close',
    zh: ''
  },
  LABEL_EDIT: {
    en: 'Edit',
    zh: ''
  },
  LABEL_SAVE: {
    en: 'Save',
    zh: ''
  },
  LABEL_SUBMIT: {
    en: 'SUBMIT',
    zh: ''
  },
  LABEL_CANCEL: {
    en: 'CANCEL',
    zh: ''
  },
  MESSAGE_REGISTER_WARNING: {
    en: 'Your first and last name cannot be changed after account creation. For questions or assistance, please call us at (800) 228-7702.',
    zh: ''
  },
  LABEL_NAME: {
    en: 'Name',
    zh: ''
  },
  LABEL_FIRST_NAME: {
    en: 'First Name',
    zh: ''
  },
  LABEL_LAST_NAME: {
    en: 'Last Name',
    zh: ''
  },
  LABEL_VALID_ZIP_CODE: {
    en: 'Please input valid US zip code',
    zh: ''
  },
  LABEL_ZIP_US_ONLY: {
    en: 'ZIP CODE (US ONLY)',
    zh: '邮编（美国）'
  },
  LABEL_ZIP: {
    en: 'Zip Code',
    zh: '邮编'
  },
  LABEL_MOBILE: {
    en: 'Mobile',
    zh: '手机'
  },
  LABEL_PHONE_NUMBER: {
    en: 'Phone Number',
    zh: ''
  },
  LABEL_FUTURIST_ALLIANCE_INVITATION_CODE: {
    en: 'Futurist Alliance Invitation Code',
    zh: ''
  },
  LABEL_SUBSCRIPTION: {
    en: 'NEWSLETTER SUBSCRIPTION',
    zh: ''
  },
  LABEL_SUBSCRIBED: {
    en: 'Subscribed',
    zh: ''
  },
  LABEL_NOT_SUBCRIBED: {
    en: 'Not Subscribed',
    zh: ''
  },
  LABEL_SUBSCRIBE_PROMPT: {
    en: 'Subscribe to the FF newsletter',
    zh: ''
  },
  LABEL_LOADING: {
    en: 'Loading',
    zh: ''
  },
  LABEL_ACCOUNT: {
    en: 'Account',
    zh: ''
  },
  LABEL_MY_ACCOUNT: {
    en: 'My Account',
    zh: ''
  },
  MESSAGE_MY_ACCOUNT: {
    en: 'Your account profile can be updated here.',
    zh: ''
  },
  LABEL_MY_ACCOUNT_HI: {
    en: 'Hi',
    zh: ''
  },
  LABEL_RESERVATIONS: {
    en: 'Preorders',
    zh: ''
  },
  MESSAGE_EXISTING_RESERVATIONS: {
    en: `${FF91_TEXT} Preorders are now open. You can view your preorders here.`,
    zh: ''
  },
  HEADER_MY_EVENTS: {
    en: 'My Events',
    zh: ''
  },
  HEADER_MY_FF_POINTS: {
    en: 'My FF Points',
    zh: ''
  },
  LABEL_MY_EVENTS: {
    en: 'FF Events',
    zh: ''
  },
  LABEL_MY_FF_POINTS: {
    en: 'FF Points',
    zh: ''
  },
  LABEL_NUMBER: {
    en: 'Number',
    zh: ''
  },
  LABEL_TIME_FF_POINTS: {
    en: 'Time',
    zh: ''
  },
  MESSAGE_MY_EVENTS: {
    en: 'If you have already scheduled an appointment, you can view your activities here.',
    zh: ''
  },
  MESSAGE_MY_FF_POINTS: {
    en: 'If you have gained FF points, you can view the details here.',
    zh: ''
  },
  WHAT_IS_FF_POINTS: {
    en: 'What is FF Points?',
    zh: ''
  },
  MY_FF_POINTS_ACTIVITIES: {
    en: 'My FF Points Activities',
    zh: ''
  },
  BUTTON_VIEW: {
    en: 'View',
    zh: ''
  },
  LABEL_HERE: {
    en: 'here',
    zh: ''
  },
  LABEL_UNKNOWN: {
    en: 'Unknown',
    zh: ''
  },
  LABEL_OLD_PASSWORD: {
    en: 'Current Password',
    zh: ''
  },
  LABEL_NEW_PASSWORD: {
    en: 'New Password',
    zh: ''
  },
  MESSAGE_ENTER_OLD_PASSWORD: {
    en: 'Please enter your old password!',
    zh: ''
  },
  MESSAGE_CONFIRM_PASSWORD: {
    en: 'Please confirm your password!',
    zh: ''
  },
  LABEL_CONFIRM_PASSWORD: {
    en: 'Confirm Password',
    zh: ''
  },
  LABEL_CHOOSE_YOUR_PASSWORD: {
    en: "Choose Your Password",
    zh: "",
  },
  MESSAGE_PASSWORD_RULES: {
    en: 'Must be at least 8 characters and include at least one number, one uppercase letter, one lowercase letter, and one special character.',
    zh: ''
  },
  MESSAGE_PASSWORD_CHANGED: {
    en: 'Your password has been changed.',
    zh: ''
  },
  LABEL_RETURN_TO_ACCOUNT: {
    en: 'RETURN TO ACCOUNT',
    zh: ''
  },
  LABEL_CLICK_TO_SUBSCRIBE: {
    en: 'Subscribe to Faraday Future News and Updates',
    zh: ''
  },
  LABEL_PREORDER_AGREEMENT: {
    en: 'FF Preorder Agreement',
    zh: ''
  },
  MESSAGE_HAVE_READ_HEREBY_AGREE: {
    en: 'I have read and hereby agree to the',
    zh: ''
  },
  MESSAGE_HAVE_READ: {
    en: 'I have read and hereby agree to the terms and conditions set forth in the Faraday Future',
    zh: ''
  },
  MESSAGE_TEST_RIDE_HAVE_READ: {
    en: 'I would like to join the FPO program and I have read and hereby agree to the terms and conditions set forth in the Faraday Future',
    zh: ''
  },
  LABEL_JOIN_WAITLIST: {
    en: `I would like to join the waitlist for the ${FF91_TEXT} Futurist Alliance`,
    zh: ''
  },
  LABEL_PRIVACY_POLICY: {
    en: 'Privacy Policy',
    zh: ''
  },
  LABEL_TERM_OF_USE: {
    en: 'Terms of Use',
    zh: ''
  },
  LABEL_REFERRAL_PROGRAM_TERMS: {
    en: 'Referral Program Terms',
    zh: ''
  },
  MESSAGE_REGISTER_RESERVATION: {
    en: `I'm making a commitment-free preorder of ${FF91_TEXT}.`,
    zh: ''
  },
  MESSAGE_AND_THE: {
    en: 'and',
    zh: ''
  },
  ERROR_MUST_AGREE_TO_REGISTER: {
    en: 'You must agree to register for an account.',
    zh: ''
  },
  ERROR_EMPTY_FIRST_NAME: {
    en: 'Please enter your first name!',
    zh: ''
  },
  ERROR_EMPTY_LAST_NAME: {
    en: 'Please enter your last name!',
    zh: ''
  },
  MESSAGE_WELCOME: {
    en: 'Welcome,',
    zh: ''
  },
  MESSAGE_SUCCESS_LOGIN: {
    en: 'You have successfully logged in. If you\'re not automatically redirected to your FF account page, please click below.',
    zh: ''
  },
  MESSAGE_SUCCESS_EDIT: {
    en: 'You have successfully modified your account.',
    zh: ''
  },
  MESSAGE_SUCCESS_JOIN_WAITLIST: {
    en: `You have successfully joined the waitlist for ${FF91_TEXT} Futurist Alliance.`,
    zh: ''
  },
  HEADER_MY_REFERRALS: {
    en: 'My Referrals',
    zh: ''
  },
  LABEL_REFERRALS: {
    en: 'Referrals',
    zh: ''
  },
  REFERRAL_SUCCESS_MESSAGE_1: {
    en: 'Find your own referral link in ',
    zh: ''
  },
  REFERRAL_SUCCESS_MESSAGE_2: {
    en: 'My Referrals',
    zh: ''
  },
  REFERRAL_SUCCESS_MESSAGE_3: {
    en: ' and share with your friends today.',
    zh: ''
  },
  REFERRAL_MYFF_SUMMARY_1: {
    en: 'You have earned ',
    zh: ''
  },
  REFERRAL_MYFF_SUMMARY_2: {
    en: ' referral credit as of today',
    zh: ''
  },
  REFERRALS_INTRO_TEXT: {
    en: 'Congratulations! You can now refer your friends by sharing the link below and you will have opportunity to earn referral credit.',
    zh: ''
  },
  MY_REFERRAL_LINK: {
    en: 'My Referral Link: ',
    zh: '我的专属链接'
  },
  REFERRAL_STEPS_HEADER: {
    en: 'How Does Referral Work?',
    zh: '快速了解推荐制'
  },
  REFERRAL_STEPS_EMPLOYEE_HEADER: {
    en: 'How do employee referrals work?',
    zh: '员工推荐如何运作'
  },
  REFERRAL_STEP1_HEADER: {
    en: 'Step 1',
    zh: '第一步'
  },
  REFERRAL_STEP1_DESCRIPTION: {
    en: 'Share the link and invite your friends.',
    zh: '将链接分享给自己的朋友'
  },
  REFERRAL_STEP2_HEADER: {
    en: 'Step 2',
    zh: '第二步'
  },
  REFERRAL_STEP2_DESCRIPTION: {
    en: 'A preorder is placed.',
    zh: '朋友成功下单'
  },
  REFERRAL_STEP2_ORDER_DESCRIPTION: {
    en: 'An order is placed.',
    zh: '已下订单'
  },
  REFERRAL_STEP3_HEADER: {
    en: 'Step 3',
    zh: '第三步'
  },
  REFERRAL_STEP3_DESCRIPTION: {
    en: `You receive a $1,500 bonus and your friend receives $1,500 off MSRP of their ${FF91_TEXT} upon delivery of their vehicle.`,
    zh: '您和朋友各得10000元人民币现金券用于购车'
  },
  REFERRAL_STEP4_HEADER: {
    en: 'Step 4',
    zh: '第四步'
  },
  REFERRAL_STEP4_DESCRIPTION: {
    en: 'Apply the cash coupon upon completing your order.',
    zh: '购车订单交付时使用现金券'
  },
  REFERRAL_LIST_SUBHEADER: {
    en: 'My Referral Activities',
    zh: '我的推荐记录'
  },
  REFERRAL_TITLE_PRIORITY_5000: {
    en: `${FF91_TEXT} Preorder - $5000 deposit`,
    zh: ''
  },
  REFERRAL_TITLE_PRIORITY_1000: {
    en: `${FF91_TEXT} Preorder - $1000 deposit`,
    zh: ''
  },
  REFERRAL_TITLE_PRIORITY: {
    en: `${FF91_TEXT} Preorder`,
    zh: ''
  },
  REFERRAL_TITLE_STANDARD: {
    en: `${FF91_TEXT} Indication of Interest`,
    zh: `${FF91_TEXT}预约`
  },
  REFERRAL_I_REFERRED: {
    en: 'I referred',
    zh: '我已推荐'
  },
  REFERRAL_I_WAS_REFERRED: {
    en: 'I was referred',
    zh: '我被推荐'
  },
  REFERRAL_CANCELLED: {
    en: 'Cancelled',
    zh: '已取消'
  },
  REFERRAL_REWARDS: {
    en: 'Rewards',
    zh: '奖励'
  },
  REFERRAL_NO_REWARDS: {
    en: 'You don\'t have any rewards yet.',
    zh: '您暂时还未获得任何权益'
  },
  REFERRAL_TOTAL_DEDUCTION: {
    en: 'Total Referral Credit: ',
    zh: '推荐总现金券：'
  },
  REFERRAL_DEDUCTION_INFO: {
    en: 'Bonus will pay out upon completion of vehicle delivery.',
    zh: ''
  },
  REFERRED_DEDUCTION_INFO: {
    en: 'The credit will be applied toward the final purchase price of your vehicle, upon acceptance of vehicle delivery.',
    zh: ''
  },
  REFERRAL_CASH_DEDUCTION: {
    en: ' cash coupon',
    zh: '现金券'
  },
  MESSAGE_REFERRALS: {
    en: 'If you have shared your link, you can view all your successful referral records here.',
    zh: ''
  },
  MESSAGE_REFERRED: {
    en: 'View all your referral activities here.',
    zh: ''
  },
  LABEL_SHARE_REFERRAL_1: {
    en: `Discover the endless possibilities of ${FF91_TEXT}`,
    zh: ''
  },
  LABEL_SHARE_REFERRAL_2: {
    en: 'Use my link to get extra referral benefits when you preorder one',
    zh: ''
  },
  LABEL_SHARE_REFERRAL_SEPARATOR: {
    en: '. ',
    zh: ''
  },
  REFERRALS_COPY_LINK_MESSAGE: {
    en: `Order your ${FF91_TEXT} through my referral code to receive $1,500 off your vehicle!`,
    zh: ''
  },
  REFERRAL_SHARE_EMAIL_FOOTER_1: {
    en: 'Thank you,',
    zh: ''
  },
  REFERRAL_SHARE_EMAIL_FOOTER_2: {
    en: 'Faraday Future',
    zh: ''
  },
  REFERRAL_SHARE_SOCIAL_SUBJECT: {
    en: 'Referral Code Share',
    zh: ''
  },
  LABEL_SHARE_REFERRAL_BODY: {
    en: `Purchase a FF 91 2.0 series with my referral link, you will earn up to 154,500 F Co-creation Points.`,
    zh: ''
  },
  LABEL_SHARE_REFERRAL_TITLE: {
    en: `Preorder the FF 91 2.0 series with @nickName@’s referral.`,
    zh: ''
  },
  REWARDS_MYFF_SUMMARY_1: {
    en: 'You have earned ',
    zh: ''
  },
  REWARDS_MYFF_SUMMARY_2: {
    en: ' FF Coins as of today',
    zh: ''
  },
  LABEL_MY_RESERVATIONS: {
    en: 'My Preorders',
    zh: ''
  },
  LABEL_MY_DESIGNS: {
    en: 'My Designs',
    zh: ''
  },
  LABEL_DESIGNS: {
    en: 'Designs',
    zh: ''
  },
  MESSAGE_EXISTING_DESIGNS: {
    en: `View your saved designs from our ${FF91_TEXT} Configurator.`,
    zh: ''
  },
  LABEL_MY_ORDERS: {
    en: 'My Orders',
    zh: ''
  },
  LABEL_BACK_TO_ACCOUNT: {
    en: 'BACK TO MY ACCOUNT',
    zh: ''
  },
  LABEL_NO_GUESTS: {
    en: 'No Guests',
    zh: ''
  },
  MESSAGE_UPGRADE: {
    en: 'If you want to upgrade to a Preorder, please call us at (800) 228-7702 or email us at',
    zh: ''
  },
  MESSAGE_UPGRADE_1: {
    en: 'You can upgrade to a Preorder',
    zh: ''
  },
  MESSAGE_UPGRADE_2: {
    en: ' in the future. Please call us at (800) 228-7702 or email us at ',
    zh: ''
  },
  MESSAGE_NO_DESIGNS_1: {
    en: 'You have no designs yet. ',
    zh: ''
  },
  MESSAGE_NO_DESIGNS_2: {
    en: `Configure your ${FF91_TEXT} now.`,
    zh: ''
  },
  MESSAGE_NO_RESERVATIONS_1: {
    en: 'You have no preorders yet. ',
    zh: ''
  },
  MESSAGE_NO_RESERVATIONS_2: {
    en: `Preorder your ${FF91_TEXT} now.`,
    zh: ''
  },
  MESSAGE_NO_RESERVATIONS_3: {
    en: 'If you think this is in error, please call us at ',
    zh: ''
  },
  MESSAGE_NO_RESERVATIONS_4: {
    en: ' or email us at ',
    zh: ''
  },
  MESSAGE_NO_ORDERS: {
    en: 'You currently have no existing orders.',
    zh: ''
  },
  ERROR_CARD_EXPIRED: {
    en: 'Card expired.',
    zh: ''
  },
  LABEL_CARD_NUMBER: {
    en: 'Card Number',
    zh: ''
  },
  ERROR_EMPTY_EXP_MONTH: {
    en: 'Select expiration month.',
    zh: ''
  },
  LABEL_EXP_MONTH: {
    en: 'Exp. Month',
    zh: ''
  },
  ERROR_EMPTY_EXP_YEAR: {
    en: 'Select year.',
    zh: ''
  },
  LABEL_EXP_YEAR: {
    en: 'Exp. Year',
    zh: ''
  },
  ERROR_EMPTY_BILLING: {
    en: 'Please enter your billing address.',
    zh: ''
  },
  LABEL_BILLING_LINE_1: {
    en: 'Billing Address 1',
    zh: ''
  },
  LABEL_BILLING_LINE_2: {
    en: 'Billing Address 2',
    zh: ''
  },
  LABEL_BILLING_LINE_2_OPTIONAL: {
    en: 'Billing Address 2 (Optional)',
    zh: ''
  },
  ERROR_EMPTY_CITY: {
    en: 'Please enter your city.',
    zh: ''
  },
  LABEL_CITY: {
    en: 'City',
    zh: ''
  },
  LABEL_UNITED_STATES: {
    en: 'United States',
    zh: ''
  },
  ERROR_EMPTY_COUNTRY: {
    en: 'Please select your billing country.',
    zh: ''
  },
  LABEL_COUNTRY: {
    en: 'Country',
    zh: ''
  },
  LABEL_BILLING_ZIP: {
    en: 'Billing ZIP Code',
    zh: ''
  },
  LABEL_REFERRAL_CODE: {
    en: 'Referral Code',
    zh: ''
  },
  LABEL_REFERRAL_CODE_OPTIONAL: {
    en: 'Referral Code (Optional)',
    zh: ''
  },
  ERROR_BILLING_STATE: {
    en: 'Please select your billing state.',
    zh: ''
  },
  LABEL_STATE: {
    en: 'State',
    zh: ''
  },
  MESSAGE_AGREE_TO_AGREEMENT: {
    en: 'By placing this order, I agree to the',
    zh: ''
  },
  LABEL_FF_RESERVATION_AGREEEMENT: {
    en: 'FF Preorder Agreement',
    zh: ''
  },
  ERROR_AGREE_TO_PROCEED: {
    en: 'You must agree to proceed.',
    zh: ''
  },
  LABEL_DATE_SAVED: {
    en: 'Date Saved: ',
    zh: ''
  },
  LABEL_CONFIRMATION_NUMBER: {
    en: 'Confirmation Number: ',
    zh: ''
  },
  LABEL_CONFIGURATION: {
    en: 'Configuration: ',
    zh: ''
  },
  LABEL_DETAILS: {
    en: 'Details',
    zh: ''
  },
  LABEL_DATE_RESERVED: {
    en: 'DATE PREORDERED',
    zh: ''
  },
  LABEL_CONFIRMATION: {
    en: 'Confirmation:',
    zh: ''
  },
  LABEL_CONFIRMATION_NO: {
    en: 'CONFIRMATION #',
    zh: ''
  },
  LABEL_ORDER_STATUS: {
    en: 'ORDER STATUS',
    zh: ''
  },
  VIEW_DETAILS: {
    en: 'View Details',
    zh: ''
  },
  LABEL_RESERVE_TYPE: {
    en: 'PREORDER TYPE',
    zh: ''
  },
  LABEL_PRIORITY_RESERVATION: {
    en: 'Preorder',
    zh: ''
  },
  LABEL_FULLY_REFUNDABLE: {
    en: 'Fully refundable',
    zh: ''
  },
  MESSAGE_PRIORITY_RESERVE: {
    en: `A Preorder makes you eligible to upgrade to the exclusive ${FF91_TEXT} Alliance the first ${FF91_TEXT}s to be produced. This preorder requires a fully refundable $5,000 deposit.`,
    zh: ''
  },
  LABEL_ALLIANCE_EDITION: {
    en: 'THE ALLIANCE',
    zh: ''
  },
  MESSAGE_ALLIANCE_EDITION: {
    en: `This special launch variant of the ${FF91_TEXT} will be a limited edition. Each unit will be uniquely personalized to visually represent the distinction of being one of the first owners of an ${FF91_TEXT}. Further details will be revealed closer to the start of production.`,
    zh: ''
  },
  DEPOSIT_AMOUNT: {
    en: 'Deposit Amount',
    zh: '订金金额'
  },
  DEPOSIT_PRIORITY_5000: {
    en: '$5,000 Deposit',
    zh: ''
  },
  DEPOSIT_PRIORITY_1000: {
    en: '$1,000 Deposit',
    zh: ''
  },
  LABEL_RESERVE_NOW: {
    en: 'Preorder Now',
    zh: '立即预定'
  },
  MESSAGE_LIMIT_QUANTITY: {
    en: 'Limited to 300 units with priority delivery',
    zh: ''
  },
  MESSAGE_UNIQUE_UI: {
    en: 'Unique & personalized Alliance digital interface',
    zh: ''
  },
  MESSAGE_PROCEEDS_DONATE: {
    en: `A portion of ${FF91_TEXT} Alliance sales proceeds will be donated for environmental protection`,
    zh: ''
  },
  MESSAGE_EXCLUSIVE_COLOR: {
    en: 'Exclusive exterior color: Silicon Valley Aluminum',
    zh: ''
  },
  MESSAGE_EXTERIOR_BADGING: {
    en: 'Unique Alliance external badging',
    zh: ''
  },
  MESSAGE_MEMBERSHIP: {
    en: 'Exclusive Membership to our ‘Futurist Club’',
    zh: ''
  },
  MESSAGE_INVALID_REFERRAL_CODE: {
    en: 'Invalid Referral Code',
    zh: ''
  },
  MESSAGE_CANNOT_USE_OWN_REFERRAL_CODE: {
    en: 'Invalid referral code. You cannot use your own referral code.',
    zh: ''
  },
  LABEL_STANDARD_RESERVATION: {
    en: 'Indication of Interest',
    zh: ''
  },
  LABEL_PRIORITY_RESERVE_NOW: {
    en: 'Preorder Now!',
    zh: ''
  },
  LABEL_ALLIANCE_INVITATION_CODE: {
    en: `Futurist Alliance Invitation Code is required to preorder your ${FF91_TEXT} Futurist Alliance. If you need to apply the invitation code, please contact FF Co-creation Consultant team  (sales@ff.com) for instructions on how to qualify for it.`,
    zh: `获得${FF91_TEXT}未来主义者联盟版的优先预订权，需要未来主义者联盟邀请码。如果您需要申请邀请码，请联系FF共创顾问团队(400-700-9191)咨询邀请码的获取资格。`
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_EDITION: {
    en: 'Alliance',
    zh: '联盟版'
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_PRICE: {
    en: 'Preorder $5,000',
    zh: '优先预订 ¥50,000'
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_EDITION_BODY: {
    en: 'Be among the world\'s first to experience the industry-leading 1,050 HP, 0-60 mph in less than 2.4s. Your preorder makes you part of the exclusive group of futurists to own the vision of tomorrow.',
    zh: `行业领先的1050匹马力，0-96公里每小时加速低于2.4秒。即刻预订拥有终极智能科技豪华电动车${FF91_TEXT}未来主义者联盟版，成为世界首批体验者，成为未来主义者的一员，尊享美好未来。`
  },
  LABEL_PRIORITY_FF91_FUTURIST: {
    en: `${FF91_TEXT} Futurist`,
    zh: `${FF91_TEXT} 未来主义`
  },
  LABEL_PRIORITY_FF91_FUTURIST_PRICE: {
    en: 'Preorder $1,500',
    zh: '预订 ¥15,000'
  },
  LABEL_PRIORITY_FF91_PRICE: {
    en: 'Preorder $1,000',
    zh: '预订 ¥10,000'
  },
  LABEL_PRIORITY_FF91_FUTURIST_BODY: {
    en: `The ${FF91_TEXT} Futurist is our class defining 1,050 HP and a revolutionary user experience designed to create a mobile, connected, and luxurious third internet living space.`,
    zh: `${FF91_TEXT}未来主义者版本拥有1050匹马力，具有革命性的用户体验，旨在创造一个移动的，互联的，豪华的第三互联网生活空间。`
  },
  LABEL_PRIORITY_FF91: {
    en: `${FF91_TEXT}`,
    zh: `${FF91_TEXT}`
  },
  LABEL_PRIORITY_FF91_BODY: {
    en: `Be among the first to experience the class defining ultra-intelligent tech luxury electric ${FF91_TEXT}. Your preorder makes you part of the exclusive group of futurists to own the vision of tomorrow.`,
    zh: `成为世界上第一批体 验终极智能科技奢华电动汽车${FF91_TEXT}的一员， 即可预定成为未来主义者，尊享美好未来。`
  },
  MESSAGE_CONFIRM_STANDARD_RESERVE: {
    en: `A Indication of Interest expresses your interest in preordering an ${FF91_TEXT} once Preorders are available for collection. While this preorder does not secure a spot in line for an ${FF91_TEXT}, it is the best way to stay informed and express your interest in placing a deposit at a later date.`,
    zh: ''
  },
  LABEL_BOOK_A_SPOT: {
    en: 'BOOK A SPOT NOW',
    zh: ''
  },
  LABEL_ACCOUNT_INFO: {
    en: 'Preorder Holder Account Information',
    zh: ''
  },
  MESSAGE_CANT_CHANGE_NAME: {
    en: 'Your First and Last name cannot be changed. For questions or assistance, please call us at (800) 228-7702.',
    zh: ''
  },
  MESSAGE_CAN_CHANGE_PHONE_ZIP: {
    en: 'You may change the phone number, ZIP code, and e-mail address linked to this preorder in',
    zh: ''
  },
  LABEL_PAGE: {
    en: 'page',
    zh: ''
  },
  LABEL_PAYMENT_BILLING: {
    en: 'Payment and Billing',
    zh: ''
  },
  LABEL_RESERVE_YOUR_91: {
    en: `Preorder Your ${FF91_TEXT}`,
    zh: ''
  },
  MESSAGE_CHOOSE_BETWEEN: {
    en: `You may choose between two types of preorders to ensure your place in line for future orders when ${FF91_TEXT} production begins.`,
    zh: ''
  },
  LABEL_STEP_1: {
    en: 'STEP 1',
    zh: ''
  },
  LABEL_STEP_2: {
    en: 'STEP 2',
    zh: ''
  },
  LABEL_STEP_3: {
    en: 'STEP 3',
    zh: ''
  },
  LABEL_RESERVATION_HEADER: {
    en: 'Preorder',
    zh: '预订'
  },
  LABEL_RESERVATIONS_HEADER: {
    en: 'Preorders',
    zh: '预订'
  },
  LABEL_RESERVATION_TYPE: {
    en: 'Preorder Type',
    zh: ''
  },
  LABEL_BILLING_INFO: {
    en: 'Billing Information',
    zh: ''
  },
  MESSAGE_NO_MATCH_TITLE: {
    en: 'Oops...',
    zh: ''
  },
  MESSAGE_NO_MATCH_DETAILS: {
    en: 'Web page vanishes...',
    zh: ''
  },
  MESSAGE_THANK_YOU_RESERVATION: {
    en: 'Thank you for placing a',
    zh: ''
  },
  MESSAGE_THANK_YOU_RESERVATION_CONTINUE: {
    en: 'order.',
    zh: ''
  },
  MESSAGE_CONFIRMATION_NUMBER_IS: {
    en: 'Your confirmation number is:',
    zh: ''
  },
  MESSAGE_CONFIRM_EMAIL_SENT: {
    en: 'A confirmation e-mail has been sent to',
    zh: ''
  },
  MESSAGE_SEE_ALL_ORDERS: {
    en: 'See all your orders in your',
    zh: ''
  },
  LABEL_RESET_PASSWORD: {
    en: 'RESET PASSWORD',
    zh: ''
  },
  MESSAGE_NEW_PASSWORD_REQUIREMENTS: {
    en: 'The new password must be at least 8 characters and include at least one number, one uppercase letter, one lowercase letter and one special character.',
    zh: ''
  },
  LABEL_ERROR_404: {
    en: 'PAGE NOT FOUND',
    zh: ''
  },
  LABEL_ERROR_TRY_AGAIN: {
    en: 'Error occurred. Please try again.',
    zh: ''
  },
  MESSAGE_PAGE_NOT_EXISTS: {
    en: 'The page you\'re looking for does not exist.',
    zh: ''
  },
  LABEL_GO_BACK: {
    en: 'GO BACK',
    zh: ''
  },
  LABEL_START_OVER: {
    en: 'START OVER',
    zh: ''
  },
  MESSAGE_FILL_IN_EMAIL: {
    en: 'Fill in your email address to have a link to reset your password sent to your inbox.',
    zh: ''
  },
  MESSAGE_SUCCESS_RESET_EMAIL_SENT: {
    en: 'Thanks! A link to reset your password has been sent to',
    zh: ''
  },
  LABEL_SEND: {
    en: 'SEND',
    zh: ''
  },
  MARK_PERIOD: {
    en: '.',
    zh: '。'
  },
  MESSAGE_PLEASE_TRY_AGAIN: {
    en: 'Please try again.',
    zh: ''
  },
  MESSAGE_FOOTER: {
    en: '© 2017 Faraday Future All rights reserved',
    zh: ''
  },

  ////////////////// BREADCRUMBS //////////////////
  CRUMBS_MY_FF: {
    en: 'My FF',
    zh: ''
  },
  CRUMBS_MY_ACCOUNT: {
    en: 'My Account',
    zh: ''
  },
  CRUMBS_MY_EVENTS: {
    en: 'My Events',
    zh: ''
  },
  CRUMBS_MY_REFERRALS: {
    en: 'My Referrals',
    zh: ''
  },
  CRUMBS_MY_FF_POINTS: {
    en: 'My FF Points',
    zh: ''
  },
  CRUMBS_MY_DESIGNS: {
    en: 'My Designs',
    zh: ''
  },
  CRUMBS_DESIGN_DETAILS: {
    en: 'Details',
    zh: ''
  },
  CRUMBS_LOGIN: {
    en: 'Login',
    zh: ''
  },
  CRUMBS_SUCCESS: {
    en: 'Success',
    zh: ''
  },
  CRUMBS_CHANGE_PASS: {
    en: 'Change Password',
    zh: ''
  },
  CRUMBS_FORGOT_PASS: {
    en: 'Forgot Password',
    zh: ''
  },
  CRUMBS_RESET_PASS: {
    en: 'Reset Password',
    zh: ''
  },
  CRUMBS_RESERVE: {
    en: 'Preorder',
    zh: '预定'
  },
  CRUMBS_PREORDER: {
    en: 'Preorder',
    zh: ''
  },
  CRUMBS_RESERVE_SUCCESS_BASE: {
    en: 'Success',
    zh: ''
  },
  CRUMBS_MY_RESERVATIONS: {
    en: 'My Preorders',
    zh: ''
  },
  CRUMBS_RESERVE_DETAILS: {
    en: 'Details',
    zh: ''
  },
  LABEL_RESERVATION_DETAILS: {
    en: 'Preorder Details',
    zh: ''
  },
  LABEL_DESIGN_DETAILS: {
    en: 'Design Details',
    zh: ''
  },
  LABEL_BACK_TO_RESERVE: {
    en: 'BACK TO PREORDERS',
    zh: ''
  },
  LABEL_RESERVATION_HOLDER: {
    en: 'Preorder Holder',
    zh: ''
  },
  LABEL_PRIORITY_FF91_CANT_WAIT: {
    en: `Can't wait? Preorder the ${FF91_TEXT} Futurist now`,
    zh: `迫不及待想体验?立刻预订${FF91_TEXT}未来主义版吧!`
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_EDITION_SOLDOUT: {
    en: `Preorders for ${FF91_TEXT} Futurist Alliance vehicles are fully booked, but you can still have an option to join the waitlist when you preorder ${FF91_TEXT} Futurist Edition. Once a spot becomes available, a member of our sales team will reach out.`,
    zh: `全球限量300台的${FF91_TEXT} 未来主义者联盟版已售罄。`
  },
  LABEL_RESERVATION_TYPE_COMING_SOON: {
    en: 'Coming Soon',
    zh: '即将推出'
  },
  LABEL_RESERVATION_TYPE_SOLD_OUT: {
    en: 'Sold Out',
    zh: '已售馨'
  },
  LABEL_RESERVATION_TYPE_SELECT: {
    en: 'Select',
    zh: ''
  },
  LABEL_RESERVATION_TYPE_SELECTED: {
    en: 'Selected',
    zh: ''
  },
  LABEL_PAYMENT_SUM: {
    en: 'Payment Summary',
    zh: ''
  },
  LABEL_ITEM_PRICE: {
    en: 'Item Price',
    zh: ''
  },
  LABEL_PAYMENT: {
    en: 'Payment',
    zh: ''
  },
  LABEL_BALANCE_DUE: {
    en: 'Balance Due',
    zh: ''
  },
  MESSAGE_QUESTION_ORDER: {
    en: 'If you have any questions with your order, please call us at (800) 228-7702 or email us at',
    zh: ''
  },
  MESSAGE_QUESTION_RESERVATION: {
    en: 'If you have any questions with your preorder, please call us at (800) 228-7702 or email us at',
    zh: ''
  },
  PLEASE_WAIT: {
    en: 'Please wait...',
    zh: ''
  },
  MESSAGE_NO_RESERVATION_1: {
    en: `Thank you for your interest in preordering ${FF91_TEXT}. Please email our sales team at`,
    zh: ''
  },
  MESSAGE_NO_RESERVATION_2: {
    en: 'and we will follow up with you in the next 24 hours to finalize your preorder.',
    zh: ''
  },
  LABEL_DISMISS: {
    en: 'Dismiss',
    zh: ''
  },
  LABEL_CHAPTER_I_COMPLETE: {
    en: 'Chapter I Complete',
    zh: ''
  },
  LABEL_BOOK_NOW: {
    en: 'Book Now',
    zh: ''
  },
  LABEL_DELIVERY: {
    en: 'Delivery',
    zh: ''
  },
  LABEL_CANCELLATION: {
    en: 'Cancellation',
    zh: ''
  },
  MESSAGE_DELIVERY_CANCELLATION_POLICY: {
    en: `Although we are making all efforts to deliver the ${FF91_TEXT} as soon as reasonably possible, you understand and agree that placing this preorder does not guarantee receipt of an ${FF91_TEXT} or a specific delivery date. You are free to cancel this preorder at any time prior to notice from us that your ${FF91_TEXT} Is ready for delivery. If you do so prior to our notice of delivery, you will obtain a full refund of any deposit.`,
    zh: ''
  },
  MESSSAGE_YOU_HAVE: {
    en: 'You have',
    zh: ''
  },
  MESSAGE_MORE_ATTEMPTS_LOGIN: {
    en: 'more attempts before your account is locked for 20 minutes.',
    zh: ''
  },
  MESSAGE_RESET_PASSWORD: {
    en: 'You may reset your password',
    zh: ''
  },
  MESSAGE_LIMITED_ACCESS: {
    en: 'Thank you for your interest in Faraday Future. We apologize for the inconvenience, but registration is not currently available in your country or region at this time. Please follow Faraday Future on social media for the latest vehicle and company updates.',
    zh: ''
  },
  LABEL_TEST_RIDE: {
    en: `${FF91_TEXT} Test Ride`,
    zh: ''
  },
  MESSAGE_TEST_RIDE_DESCRIPTION_HAS_CODE: {
    en: `Welcome and thank you for your interest in Faraday Future. We can't wait to having you experience this ultra-modern high-performance electric vehicle. Complete the first step below to schedule your ${FF91_TEXT} test ride. If you have any questions, please feel free to call us at (800) 228-7702 or email us at product@ff.com.`,
    zh: ''
  },
  MESSAGE_TEST_RIDE_DESCRIPTION_NO_CODE: {
    en: 'Test rides are not currently available without an invitation code. Fill out the form below and we will notify you when test rides become public.',
    zh: ''
  },
  PLACEHOLDER: {
    en: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. At unde assumenda tenetur, mollitia corporis nostrum ex architecto accusamus. Atque dolorem iusto architecto quos eveniet blanditiis nihil doloribus impedit voluptatum fugit.',
    zh: ''
  },
  LABEL_PLACEHOLDER: {
    en: 'Past Test Ride Content',
    zh: ''
  },
  LABEL_QUESION_HAVE_INVITATION_CODE: {
    en: 'Do you have an invitation code?',
    zh: ''
  },
  LABEL_HAVE_CODE: {
    en: 'I have a code',
    zh: ''
  },
  LABEL_NO_CODE: {
    en: 'I do not have a code',
    zh: ''
  },
  LABEL_INVITATION_CODE: {
    en: 'Invitation Code',
    zh: ''
  },
  ERROR_INVALID_INVITATION_CODE: {
    en: 'Invalid Invitation Code',
    zh: ''
  },
  ERROR_REQUIRED_FIELD: {
    en: 'This field is required',
    zh: ''
  },
  ERROR_INVITATION_CODE_REQUIRED: {
    en: 'Invitation Code is required.',
    zh: ''
  },
  ERROR_INVITATION_CODE_INVALID: {
    en: 'Invitation Code is invalid.',
    zh: ''
  },
  ERROR_INVITATION_CODE_USED: {
    en: 'Invitation Code is already redeemed.',
    zh: ''
  },
  ERROR_INVITATION_CODE_EXPIRED: {
    en: 'Invitation Code is already expired.',
    zh: ''
  },
  LABEL_EVENT_FINISHED: {
    en: 'Sorry, the event is finished.',
    zh: ''
  },
  LABEL_EVENT_FINISHED_FILL_FORM: {
    en: 'Fill out the form below and get updates for coming events.',
    zh: ''
  },
  LABEL_PREFERRED_CONTACT_METHOD: {
    en: 'Preferred Contact Method',
    zh: ''
  },
  LABEL_PHONE: {
    en: 'Phone',
    zh: ''
  },
  MESSAGE_PROMPT_CREATE_PASSWORD: {
    en: 'Create a password to complete your My FF account. This password will be used for our website and our app. Must be at least 8 characters including 1 or more numbers.',
    zh: ''
  },
  MESSAGE_AGREE_TO_FF: {
    en: 'I agree to FF\'s',
    zh: ''
  },
  LABEL_TERMS_OF_SERVICES: {
    en: 'Terms of Service',
    zh: ''
  },
  ERROR_MATCH_PASSWORD: {
    en: 'The passwords do not match!',
    zh: ''
  },
  ERROR_MATCH_EMAIL: {
    en: 'The emails do not match!',
    zh: ''
  },
  ERROR_NOT_CURRENT_EMAIL: {
    en: 'The input is not current email',
    zh: ''
  },
  LABEL_COMPLETE_PAYMENT: {
    en: 'Complete Payment',
    zh: '预订'
  },
  LABEL_COMPLETE_RESERVATION: {
    en: 'Complete Preorder',
    zh: '完成预订'
  },
  ERROR_FETCH_USER_EMAIL: {
    en: 'Could not fetch the users email account',
    zh: '无法获取用户电子邮件'
  },
  RESERVE_DESCRIPTION_META_TAG: {
    en: `Discover the endless possibilities of the ${FF91_TEXT} Futurist Edition, be a part of a truly unique and exclusive ownership experience through the membership in our Spire Club and enjoy a focus on design and technology exclusive to you.`,
    zh: ''
  },
  LABEL_PRIORITY_RESERVE_DESCRIPTION_1: {
    en: `Discover the endless possibilities of the ${FF91_TEXT} Futurist Edition. Be among the world's first to experience the ${FF91_TEXT} through the Futurist Experience Officer Program.`,
    zh: ''
  },
  LABEL_PRIORITY_RESERVE_DESCRIPTION_2: {
    en: `Be among the world's first to experience the ${FF91_TEXT} Futurist Limited Edition, join the Futurist Product Officer Program, and earn FF points through co-creation activities.`,
    zh: ''
  },
  LABEL_PRIORITY_RESERVE_NOW_DESCRIPTION_1: {
    en: `Preorder the groundbreaking ${FF91_TEXT} Futurist Edition, join the Futurist Product Officer Program, and earn FF points through co-creation activities.`,
    zh: ''
  },
  LABEL_STANDARD_RESERVE_DESCRIPTION_1: {
    en: `A Indication of Interest expresses your interest in preordering an ${FF91_TEXT} but does not hold a spot in line.`,
    zh: ''
  },
  LABEL_STANDARD_RESERVE_DESCRIPTION_2: {
    en: 'You can upgrade to a Preorder at any time.',
    zh: ''
  },
  LABEL_STANDARD_RESERVE_DESCRIPTION_2_ALT: {
    en: ' You can cancel it or upgrade to a Preorder at any time.',
    zh: ''
  },
  LABEL_DATE: {
    en: 'DATE:',
    zh: ''
  },
  LABEL_TIME: {
    en: 'TIME:',
    zh: ''
  },
  LABEL_LOCATION: {
    en: 'LOCATION:',
    zh: ''
  },
  LABEL_GUESTS: {
    en: 'GUESTS:',
    zh: ''
  },
  LABEL_ADD_TO_CALENDAR: {
    en: 'Add To Calendar',
    zh: ''
  },
  LABEL_SHARE: {
    en: 'Share',
    zh: ''
  },
  LABEL_SHARE_VIA: {
    en: 'Share via',
    zh: ''
  },
  LABEL_STATUS: {
    en: 'Status: ',
    zh: ''
  },
  LABEL_CANCELLED: {
    en: 'Cancelled',
    zh: ''
  },
  LABEL_SCHEDULED: {
    en: 'Scheduled',
    zh: ''
  },
  LABEL_EXPIRED: {
    en: 'Expired',
    zh: ''
  },
  LABEL_DATE_AND_TIME: {
    en: 'Date & Time:',
    zh: ''
  },
  LABEL_OPEN_APP: {
    en: 'Open App',
    zh: ''
  },
  LABEL_TEST_RIDE_LOCATION: {
    en: 'Location:',
    zh: ''
  },
  LABEL_TEST_RIDE_GUESTS: {
    en: 'Guests:',
    zh: ''
  },
  LABEL_TEST_RIDE_GUESTS_NONE: {
    en: 'No Guests',
    zh: ''
  },
  LABEL_TEST_RIDE_RESCHEDULE: {
    en: 'Reschedule',
    zh: ''
  },
  LABEL_RESCHEDULE_INFO_MESSAGE: {
    en: 'To ensure better experience, we can only accept reschedule at certain time beforehand, if you still want to reschedule, please contact us via product@ff.com.',
    zh: ''
  },
  LABEL_TEST_RIDE_CANCEL: {
    en: 'Cancel',
    zh: ''
  },
  LABEL_TEST_RIDE_EXPLORE_TITLE: {
    en: 'Test Ride',
    zh: ''
  },
  LABEL_TEST_RIDE_EXPLORE_SECTION_1: {
    en: 'Thanks for your interest in our test ride event. We will keep you updated once the test ride is open to public.',
    zh: ''
  },
  LABEL_TEST_RIDE_EXPLORE_SECTION_2: {
    en: `You may click the button below to explore more about ${FF91_TEXT}.`,
    zh: ''
  },
  LABEL_TEST_RIDE_EXPLORE_BUTTON: {
    en: `Explore ${FF91_TEXT}`,
    zh: ''
  },
  ERROR_SERVER: {
    en: 'Server Error. Please try again later.',
    zh: ''
  },
  ERROR_CHECK_FIELDS: {
    en: 'We are unable to finish your request. Please check fields above.',
    zh: ''
  },
  ERROR_CONTACT_SALES: {
    en: 'We are unable to finish your request, please try again. If problem persists, contact sales@ff.com or 1.800.228.7702 for immediate support.',
    zh: ''
  },
  ERROR_EXISTING_EMAIL: {
    en: 'Email address already exists.',
    zh: '邮箱已经注册'
  },
  ERROR_EMAIL_NOT_EXIST: {
    en: 'Email does not exist.',
    zh: '邮箱不存在'
  },
  ERROR_EMPTY_RADIO_BUTTON: {
    en: 'Please select an option',
    zh: '请选择一个选项'
  },
  LABEL_USER_INFORMATION: {
    en: 'User Information',
    zh: '用户信息'
  },
  LABEL_NEXT: {
    en: 'Next',
    zh: '下一步'
  },
  LABEL_LEARN_MORE: {
    en: 'Learn more',
    zh: '查看详情'
  },
  LABEL_FUTURIST_PROGRAMS: {
    en: 'Futurist Programs',
    zh: ''
  },
  LABEL_COCREATION_POINTS_PROGRAM: {
    en: 'Co-creation Points program',
    zh: ''
  },
  LABEL_COCREATION_POINTS_PROGRAM_CONTENT_1: {
    en: 'FF Co-creation Point (CP) is used to award user\'s participation in FF value co-creating activities and plays an important role in FF User Ecosystem.',
    zh: ''
  },
  LABEL_COCREATION_POINTS_PROGRAM_CONTENT_2: {
    en: 'Your CP will be issued to and stored in your FF account and can be viewed through My FF.',
    zh: ''
  },
  LABEL_LEARN_MORE_TERMS_OF_USE_CONTENT_1: {
    en: 'The CP you receive from preordering the vehicle will be automatically activated once you\'ve successfully paid off the vehicle price.',
    zh: ''
  },
  LABEL_LEARN_MORE_TERMS_OF_USE_CONTENT_2: {
    en: 'The CP is valid for two years after being activated.',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM: {
    en: 'Futurist Product Officer Program',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_CONTENT_1: {
    en: 'Futurist Product Officer (FPO) program is a value co-creation platform between FF and users.',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_CONTENT_2: {
    en: 'The FPOs contribute to the creation of the future of mobility by participating in product testing & feedback, new ideas generation and communication.',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_START_TITLE: {
    en: 'Would you like to register as a FPO?',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_START_SUBTITLE: {
    en: `We would like to invite you to join an exclusive experience designed by Faraday Future - our Futurist Product Officer program. Here, you'll have a highly customized and hands-on experience with ${FF91_TEXT} so you can help us shape the future of the mobility industry.`,
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_FORM_TITLE: {
    en: 'Futurist Product Officer Registration',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_FORM_SUBTITLE: {
    en: "By joining the Futurist Product Officer(FPO) program, you are signing up for an exclusive experience designed by Faraday Future where you will be helping to shape the future of the mobility industry through your feedback and ideas. You will have the opportunity to work directly with FF's engineering and design teams to turn your ideas and creativity into a reality. Let's begin our co-creation experience by answering a few questions below. Additional information is required:",
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_RESERVATION_HOLDER: {
    en: `Are you a ${FF91_TEXT} preorder holder?`,
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_MAKE_MODEL: {
    en: 'What make and model vehicle do you drive?',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DRIVING_STATUS: {
    en: 'Which best describes your daily driving status?',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS: {
    en: 'What topic(s) are you interested in?',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_SUCCESS_TITLE: {
    en: 'Congratulations!',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_SUCCESS_SUBTITLE: {
    en: 'You have officially joined the Futurist Product Officer (FPO) Program.',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DOWNLOAD_DESCRIPTION: {
    en: 'Download the FF Intelligence APP now and send us your creative ideas and suggestions. You Contribute, We Reward.',
    zh: ''
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_ALREADY_FPO_TITLE: {
    en: 'You are already a Futurist Product Officer!',
    zh: ''
  },
  LABEL_DRIVER: {
    en: 'Driver',
    zh: ''
  },
  LABEL_PASSENGER: {
    en: 'Passenger',
    zh: ''
  },
  LABEL_PERFORMANCE: {
    en: 'Performance',
    zh: ''
  },
  LABEL_INTELLIGENCE: {
    en: 'Intelligence',
    zh: ''
  },
  LABEL_TECHNOLOGY: {
    en: 'Technology',
    zh: ''
  },
  LABEL_LUXURY: {
    en: 'Luxury',
    zh: ''
  },
  LABEL_OTHER: {
    en: 'Other',
    zh: ''
  },
  LABEL_MAKE_MODEL_ERROR: {
    en: 'Field is required',
    zh: ''
  },
  LABEL_DRIVING_STATUS_ERROR: {
    en: 'Please select a driving status',
    zh: ''
  },
  LABEL_TOPICS_ERROR: {
    en: 'Please select at least one topic',
    zh: ''
  },
  LABEL_FF91: {
    en: `${FF91_TEXT}`,
    zh: ''
  },
  LABEL_END_USER_AGREEMENT: {
    en: 'End User License Agreement',
    zh: ''
  },
  LABEL_DELIVERY_AND_CANCELLATION: {
    en: 'Delivery & Cancellation',
    zh: ''
  },
  LABEL_EXPIRATION_MONTH_OPTION_DEFAULT: {
    en: 'Select month',
    zh: ''
  },
  LABEL_EXPIRATION_YEAR_OPTION_DEFAULT: {
    en: 'Select year',
    zh: ''
  },
  LABEL_STATE_OPTION_DEFAULT: {
    en: 'Select state',
    zh: ''
  },
  LABEL_PROFILE: {
    en: 'Profile',
    zh: ''
  },
  LABEL_SETTINGS: {
    en: 'Settings',
    zh: ''
  },
  LABEL_919_EVENT_REGISTER_TITLE: {
    en: "Secure Your Ticket",
    zh: ""
  },
  LABEL_919_EVENT_REGISTER_SUBTITLE: {
    en: "For all our participants’ safety and hospitality, please enter your information below to secure your tickets to the 919 Co-Creation Festival:",
    zh: ""
  },
  LABEL_919_EVENT_REGISTER_BUTTON: {
    en: "Secure your ticket",
    zh: ""
  },
  LABEL_SUCCESS_MODAL_TITLE: {
    en: "Successful",
    zh: ""
  },
  LABEL_COMPLETE_ORDER_SUCCESS_DESCRIPTION: {
    en: "Production of the vehicle will begin soon",
    zh: ""
  },
  LABEL_COMPLETE_ORDER_SUCCESS_WITH_ESTIMATE: {
    en: " with an estimate date of ",
    zh: ""
  },
  LABEL_VIEW_MY_ORDER: {
    en: "View my order",
    zh: ""
  },
  LABEL_PRICE_INCLUDED: {
    en: "Included",
    zh: ""
  },
  ERROR_VEHICLE_INVALID: {
    en: "The configuration you selected is not valid. Please start over.",
    zh: ""
  },
  ERROR_ORDER_INVALID: {
    en: "The preorder applied on your configuration is not valid. Please start over.",
    zh: ""
  },
  ERROR_CONFIGURATION_NOT_FOUND: {
    en: "The configuration is not found. Please start over.",
    zh: ""
  },
  ERROR_TITLE_ORDER_NOT_FOUND: {
    en: "Order not found",
    zh: ""
  },
  ERROR_MESSAGE_ORDER_NOT_FOUND: {
    en: "There was a problem finding your order. Please try again.",
    zh: ""
  },
  LABEL_TITLE_FX_SIMPLE_PREORDER: {
    en: "Preorder Faraday X",
    zh: ""
  },
  LABEL_FX_SIMPLE_PREORDER_DESCRIPTION: {
    en: "The FX Super One redefines mobility with a First-Class AI-powered MPV experience, offering unmatched versatility for elevated lifestyles.",
    zh: ""
  },
};
