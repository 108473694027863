import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Containers
import Scaffold from '~/app/containers/Scaffold';

// Utilities
import ResetScroll from '~/app/utils/ResetScroll';
import Helmet from 'react-helmet';
import _ from 'lib/gettext';
import loadable from '@loadable/component';

const Error404 = loadable(() => import(/* webpackChunkName: "Error404" */ '~/app/containers/Error404'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ResetScroll />
        <Helmet defaultTitle={_('LABEL_TITLE_DEFAULT')} />
        <Switch>
          <Route path="/:locale(us|cn)/404" component={Error404} />
          <Route path="/:locale(us|cn)/**" component={Scaffold} />
          <Route path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;
